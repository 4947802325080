import React from 'react';
import T from 'prop-types';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { NotifyIcon } from 'web-components';

import { ERROR, IN_PROGRESS, SUCCESS } from '../../attrs/status';

const Wrapper = styled.div`
  min-height: 80px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DefaultErrorComponent = ({ reloadAction }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {t('errors.generic')}
      {reloadAction && (
        <>
          {' '}
          {t('errors.try_reload')}
          <IconButton aria-label="reload" onClick={reloadAction} color="inherit" size="small">
            <NotifyIcon iconName="reload" />
          </IconButton>
        </>
      )}
    </Wrapper>
  );
};

const LoadingStatusComponent = ({
  loadingState: { status },
  ignoreError,
  reloadAction,
  renderComponent: RenderedComponent,
  loadingComponent: RenderedLoadingComponent,
  errorComponent: RenderedErrorComponent,
  renderProps = {}
}) => {
  const ActiveErrorComponent = RenderedErrorComponent || DefaultErrorComponent;

  switch (status) {
    case IN_PROGRESS:
      return <RenderedLoadingComponent />;
    case ERROR:
      return !ignoreError && <ActiveErrorComponent reloadAction={reloadAction} />;
    case SUCCESS:
      return RenderedComponent ? <RenderedComponent {...renderProps} /> : null;
    default:
      return null;
  }
};

LoadingStatusComponent.propTypes = {
  loadingState: T.shape({
    status: T.string,
    message: T.oneOfType([T.number, T.string])
  }).isRequired,
  loadingComponent: T.oneOfType([T.node, T.string, T.func]).isRequired,
  errorComponent: T.oneOfType([T.node, T.string, T.func]),
  ignoreError: T.bool.isRequired,
  renderComponent: T.oneOfType([T.node, T.string, T.func]),
  renderProps: T.shape({}),
  reloadAction: T.func
};

LoadingStatusComponent.defaultProps = {
  renderComponent: null,
  errorComponent: null,
  renderProps: null,
  reloadAction: null
};

DefaultErrorComponent.propTypes = {
  reloadAction: T.func
};

DefaultErrorComponent.defaultProps = {
  reloadAction: null
};

export default LoadingStatusComponent;
