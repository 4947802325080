import React, { useState } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PageHeader, useResolutionCheck } from 'web-components';

import GatewayTable from '../../components/GatewayTable';
import { ContainerHeader, ContentWrapper } from '../../elements';
import { Button } from '@mui/material';
import UpdateGatewaysModal from './UpdateGatewaysModal';

const Gateways = ({ gateways }) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useResolutionCheck();
  const [updateGatewaysModalOpen, setUpdateGatewaysModalOpen] = useState(false);

  const handleCloseModal = () => setUpdateGatewaysModalOpen(false);

  return (
    <>
      <ContainerHeader className="withoutShadow">
        <PageHeader.Title>
          <PageHeader.Title.Main>{t('gateways.title')}</PageHeader.Title.Main>
          <PageHeader.Title.Sub>{t('gateways.subtitle')}</PageHeader.Title.Sub>
        </PageHeader.Title>
        <Button
          variant="outlined"
          sx={{ p: 1.5, gap: 1, display: 'flex', height: '55px', minWidth: '156px' }}
          onClick={() => setUpdateGatewaysModalOpen(true)}
        >
          {t('gateways.update_all_gateways.button')}
        </Button>
      </ContainerHeader>
      <UpdateGatewaysModal open={updateGatewaysModalOpen} handleClose={handleCloseModal} />
      <ContentWrapper
        style={isDesktop ? { paddingLeft: '5rem', paddingRight: '5rem', paddingTop: 0 } : {}}
        className={!isMobile && 'withPageHeader'}
        maxWidth={false}
      >
        <GatewayTable rows={gateways} />
      </ContentWrapper>
    </>
  );
};

Gateways.propTypes = {
  gateways: T.instanceOf(Object).isRequired
};

export default Gateways;
