import moment from 'moment';
import T from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { Box, Button, Card, CardHeader, Skeleton, Stack } from '@mui/material';
import { DataTable, NotifyIcon, NotifyTypo } from 'web-components';

import { COLOR_PRIMARY } from 'attrs/colors';
import { AddLicensesValidation } from 'attrs/formValidation';
import { LICENSES_LABEL, LICENSES_NAMES_MAP, NOTI_LICENSES, NOTI_PRODUCT } from 'attrs/licenses';

import EmptyState from 'components/EmptyState';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SyncIcon from '@mui/icons-material/Sync';

import withLoadingState from 'helpers/withLoadingState';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { createLicense, loadLicenses, loadLicensesByResume, updateLicense } from 'redux/licenses/actions';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE } from 'attrs/pagination';
import { getValueToNullableCell } from 'helpers/stringHandler';
import { getLicenses } from 'redux/rootSelectors';
import { Accordion, AccordionDetails, AccordionSummary, AccordionSummarySimple } from './elements';

import Footer from '../../../../components/DataTable/Footer';
import ProgressBar from '../../../../components/ProgressBar';
import RenewLicence from './LicenceRenewModal';
import LicenseModal from './LicenseModal';
import LicensesDetailsModal from './LicensesDetailsModal';
import { isLoading, isSuccess } from '../../../../helpers/utils';

const successToastProps = {
  variant: 'success',
  autoHideDuration: 3000
};

const errorToastProps = {
  variant: 'error',
  autoHideDuration: 3000
};

const labelAmend = 'customers.form';

const licenseData = license => [
  {
    label: `${labelAmend}.license_id`,
    value: getValueToNullableCell(license?.license_serial_number),
    id: 1
  },
  {
    label: `${labelAmend}.add_licenses_modal.activation_date`,
    value: moment(license?.activation_date).format('DD/MM/YYYY, HH:mm'),
    id: 2
  },

  {
    label: `${labelAmend}.add_licenses_modal.expiration_date`,
    value: moment(license?.expiration_date).format('DD/MM/YYYY, HH:mm'),
    id: 3
  },
  {
    label:
      NOTI_PRODUCT === license?.product
        ? `${labelAmend}.remaining_time`
        : `${labelAmend}.add_licenses_modal.number_tasks.title`,
    value: NOTI_PRODUCT === license?.product ? <ProgressBar license={license} /> : <ProgressBar license={license} />,
    id: 4
  },
  {
    label: `${labelAmend}.add_licenses_modal.note`,
    value: getValueToNullableCell(license?.note),
    id: 5
  }
];

const newLicenseValues = {
  product: NOTI_PRODUCT,
  plan: NOTI_LICENSES.TRIAL_ADVANCED,
  monitoring_points: 0,
  activation_date: new Date(),
  expiration_date: new Date()
};

const calculatePointsUsed = (activationDate, expirationDate, monitoringPoints, currentDate) => {
  const totalDays = Math.ceil((expirationDate - activationDate) / (24 * 60 * 60 * 1000));
  const daysElapsed = Math.ceil((currentDate - activationDate) / (24 * 60 * 60 * 1000));
  const pointsPerDay = monitoringPoints / totalDays;
  return pointsPerDay * daysElapsed;
};

const ConnectedLicensesContent = ({
  data,
  loadingDataByResume,
  dataByResume,
  organizationId,
  setSelectedUUID,
  selectedUUID
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    { id: 'transaction_id', label: t(`licenses.transactions_history_box.transaction_id`), sortable: false },
    { id: 'created_at', label: t(`licenses.transactions_history_box.operation_date`), sortable: false },
    { id: 'type', label: t(`licenses.transactions_history_box.type`), sortable: false },
    { id: 'activation_date', label: t(`licenses.transactions_history_box.activation_date`), sortable: false },
    { id: 'expiration_date', label: t(`licenses.transactions_history_box.expiration_date`), sortable: false },
    { id: 'note', label: t(`licenses.transactions_history_box.note`), sortable: false }
  ];

  const [licenseList, setLicenseList] = useState(data || []);
  const [, setPointsUsed] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isModalRenew, setIsModalRenew] = useState(false);
  const [licenseDataModal, setLicenseDataModal] = useState({});
  const [notifyCount, setNotifyCount] = useState(0);
  const [editLicense, setEditLicense] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [preventiveCount, setPreventiveCount] = useState(0);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [hasLicenseToEdit, setHasLicenseToEdit] = useState(false);
  const [dataGroupedByProduct, setDataGroupedByProduct] = useState({});
  const [expandedSubscriptionHistory, setExpandedSubscriptionHistory] = React.useState(false);
  const { creatingState, editingState } = useSelector(getLicenses);

  useEffect(() => {
    if ((isSuccess(creatingState.status) || isSuccess(editingState.status)) && isSaving) {
      setIsSaving(false);
      dispatch(loadLicenses(organizationId));
    }
  }, [creatingState.status, editingState.status]);

  useEffect(() => {
    if (data && data.length > 0) {
      setLicenseList(data);
    } else {
      setLicenseList([]);
    }
  }, [data]);

  const [, setCurrentPage] = useState(1);
  const [, setIsLoading] = useState(false);

  const rowsPerPage = PAGINATION_DEFAULT_ROWS_PER_PAGE;

  const [hasLicencesInitial] = useState(!!licenseList?.length);
  const [hasLicences] = useState(hasLicencesInitial);

  const addLicense = () => setShowModal(true);

  const handleEditLicense = license => {
    setEditLicense({ ...license, note: undefined });
    setHasLicenseToEdit(true);
  };

  const handleCloseLicenseModal = () => setIsModalOpen(false);
  const handleCloseLicenseRenew = () => setIsModalRenew(false);

  const handleSubmitLicense = values => {
    const newLicense = {
      license: {
        ...values
      },
      organization_id: organizationId
    };

    try {
      dispatch(createLicense(newLicense));
      enqueueSnackbar(t('licenses.success_create_material'), successToastProps);
      setIsSaving(true);
    } catch {
      enqueueSnackbar(t('licenses.error_create_material'), errorToastProps);
    }
  };

  const handleSubmitEditLicense = values => {
    const newLicense = {
      license: {
        ...values
      },
      organization_id: organizationId
    };

    try {
      dispatch(updateLicense(newLicense, organizationId));
      enqueueSnackbar(t('licenses.success_update_material'), successToastProps);
      setIsSaving(true);
    } catch {
      enqueueSnackbar(t('licenses.error_create_material'), errorToastProps);
    }
  };

  const handleRenewClick = license => {
    setIsModalRenew(true);
    setLicenseDataModal(license);
  };

  const groupBy = (arr, property) =>
    arr.reduce((memoOriginal, x) => {
      const memo = memoOriginal;
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});

  useEffect(() => {
    setDataGroupedByProduct(groupBy(licenseList, 'product'));
  }, [licenseList]);

  useEffect(() => {
    let newNotifyCount = 0;
    let newPreventiveCount = 0;

    licenseList.forEach(obj => {
      if (obj.product === 'NOTIFY') {
        newNotifyCount += 1;
      } else if (obj.product === 'MAINT_PREVENTIVE') {
        newPreventiveCount += 1;
      }
    });
    setNotifyCount(newNotifyCount);
    setPreventiveCount(newPreventiveCount);
  }, [licenseList]);

  useEffect(() => {
    const currentDate = new Date();

    const updatedData = licenseList.map(item => {
      const {
        activation_date: activationDate,
        expiration_date: expirationDate,
        monitoring_points: monitoringPoints,
        ...rest
      } = item;

      const usedPoints = calculatePointsUsed(
        new Date(activationDate),
        new Date(expirationDate),
        monitoringPoints,
        currentDate
      );

      return {
        activation_date: activationDate,
        expiration_date: expirationDate,
        monitoring_points: monitoringPoints,
        usedPoints,
        ...rest
      };
    });

    setPointsUsed(updatedData);
  }, [licenseList]);

  const handleShowDetails = rowData => {
    setSelectedRowData(rowData);
    setIsModalOpen(true);
  };

  const handleChangePage = page => {
    setIsLoading(true);
    setCurrentPage(page);
    dispatch(loadLicensesByResume(selectedUUID, rowsPerPage, page));
  };

  const handleChangeExpandHistory = subscriptionUuid => (event, isExpanded) => {
    setExpandedSubscriptionHistory(isExpanded ? subscriptionUuid : false);
    setSelectedUUID(subscriptionUuid);
  };

  return (
    <Card>
      <CardHeader
        style={{ paddingBottom: '16px' }}
        sx={{ px: 5, py: 4, alignItems: 'flex-start' }}
        action={
          <Button
            variant={licenseList.length > 0 ? 'outlined' : 'contained'}
            onClick={addLicense}
            sx={{ gap: 1, p: 1.75, pr: 1.5 }}
            data-selector="add-license"
          >
            <NotifyIcon iconName="plus" fontSize="medium" />
            {t(`${labelAmend}.add_licenses`)}
          </Button>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mr: '10px' }}>{t(`${labelAmend}.licenses`)}</Box>
            {notifyCount !== 0 && preventiveCount !== 0 ? (
              <Box
                sx={{
                  height: '24px',
                  borderRadius: '10px',
                  padding: '0px 6px 0px 6px',
                  background: 'rgba(88, 186, 170, .2)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <NotifyTypo.Subtitle1 sx={{ color: '#000' }}>{t(`${labelAmend}.active_license`)}</NotifyTypo.Subtitle1>
                <Stack direction="row" spacing={1} sx={{ ml: 1, alignItems: 'center' }}>
                  <NotifyIcon iconName="notifyIcon" fontSize="8" sx={{ color: 'rgba(27, 107, 101, 1)' }} />
                  <Box>{notifyCount}</Box>
                  <NotifyIcon iconName="preventiveIcon" fontSize="10" sx={{ color: 'rgba(27, 107, 101, 1)' }} />
                  <Box>{preventiveCount}</Box>
                </Stack>
              </Box>
            ) : null}
          </Box>
        }
        titleTypographyProps={{
          fontWeight: 'bold',
          variant: 'Body1'
        }}
      />

      {Object.keys(dataGroupedByProduct).map(licenseGroup => {
        const updatedLicenseGroup = LICENSES_NAMES_MAP[licenseGroup] || licenseGroup;

        return (
          <Accordion
            key={licenseGroup}
            style={{ flex: 1, paddingTop: 0 }}
            data-selector="license-cards"
            sx={{ py: 2, pl: 4, my: 1 }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id="transactions-history-header" sx={{ pl: 0 }}>
              <NotifyTypo.Heading2 fontWeight="bold">{updatedLicenseGroup}</NotifyTypo.Heading2>
            </AccordionSummary>
            {!licenseList && <EmptyState type="licenses" top={0} />}
            {licenseList &&
              dataGroupedByProduct[licenseGroup].map(license => (
                <AccordionDetails key={license.subscription_uuid} gap={1.25} sx={{ pr: 5 }}>
                  <Box
                    sx={{ py: 5, borderBottom: '1px solid #CECECE', pt: '16px', pb: '16px' }}
                    data-selector="license-cards-item"
                  >
                    <CardHeader
                      sx={{ p: 0, pb: 1, alignItems: 'flex-start' }}
                      action={
                        <Stack flexDirection="row" gap="3.125rem">
                          <Button sx={{ gap: 1.5 }} onClick={() => handleRenewClick(license)}>
                            <SyncIcon fontSize="medium" />
                            {t(`${labelAmend}.renew`)}
                          </Button>
                          <Button
                            sx={{ gap: 1.3 }}
                            onClick={() => handleEditLicense(license)}
                            data-selector="edit-license"
                          >
                            <NotifyIcon iconName="edit" style={{ color: COLOR_PRIMARY }} fontSize="medium" />
                            {t(`${labelAmend}.edit`)}
                          </Button>
                        </Stack>
                      }
                      title={
                        <Box sx={{ display: 'flex' }}>
                          <NotifyTypo.InfoText fontWeight="bold" sx={{ pt: 0.3 }}>
                            {t(LICENSES_LABEL[license.plan])}
                          </NotifyTypo.InfoText>
                          <Box sx={{ ml: 2, py: 0.1, px: 1, background: '#E6F5F4', borderRadius: '20px' }}>
                            {license?.total_used_monitoring_points}/{license?.monitoring_points}
                          </Box>
                        </Box>
                      }
                      titleTypographyProps={{
                        fontWeight: 'bold',
                        variant: 'Body1'
                      }}
                    />
                    <Accordion
                      sx={{ pt: 5 }}
                      expanded={expandedSubscriptionHistory === license.subscription_uuid}
                      onChange={handleChangeExpandHistory(license.subscription_uuid)}
                    >
                      <AccordionSummarySimple expandIcon={<ExpandMoreIcon />} id="transactions-history-header">
                        <Stack direction="row" gap={5} flexWrap="wrap">
                          {licenseData(license)?.map(info => (
                            <div key={`licenseDataKey-${info?.id}`} style={{ minWidth: 210 }}>
                              <NotifyTypo.InfoText fontWeight="bold">{t(info?.label)}</NotifyTypo.InfoText>
                              {info?.value ? <NotifyTypo.Body1>{info?.value || ''}</NotifyTypo.Body1> : <Skeleton />}
                            </div>
                          ))}
                        </Stack>
                      </AccordionSummarySimple>
                      <AccordionDetails sx={{ my: 2, pl: 0 }}>
                        <DataTable
                          hover
                          columns={[...columns]}
                          isLoading={isLoading(loadingDataByResume)}
                          rows={((dataByResume || {}).data || []).map(transaction => ({
                            transaction_id: <Box sx={{ fontWeight: '400' }}>{transaction.transaction_id}</Box>,
                            activation_date: (
                              <Box sx={{ fontWeight: '400' }}>
                                {moment(transaction.activation_date).format('DD/MM/YYYY, HH:mm')}
                              </Box>
                            ),
                            type: transaction.operation ? (
                              <Box sx={{ fontWeight: '400' }}>
                                {t(
                                  `licenses.transactions_history_box.history_type.${(
                                    transaction.operation || ''
                                  ).toLowerCase()}`
                                )}
                              </Box>
                            ) : (
                              '---'
                            ),
                            expiration_date: (
                              <Box sx={{ fontWeight: '400' }}>
                                {moment(transaction.expiration_date).format('DD/MM/YYYY, HH:mm')}
                              </Box>
                            ),
                            created_at: (
                              <Box sx={{ fontWeight: '400' }}>
                                {moment(transaction.created_at).format('DD/MM/YYYY, HH:mm')}
                              </Box>
                            ),
                            note: transaction.note ? (
                              <Box
                                sx={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '150px',
                                  fontWeight: '400'
                                }}
                              >
                                {transaction.note}
                              </Box>
                            ) : (
                              '---'
                            )
                          }))}
                          onClick={handleShowDetails}
                          footer={
                            <Footer
                              currentPage={dataByResume.page || 1}
                              totalPages={dataByResume.total_pages || 1}
                              totalLength={dataByResume.total_length || 1}
                              totalItemsPerPage={dataByResume.limit || 0}
                              onChange={handleChangePage}
                            />
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </AccordionDetails>
              ))}
          </Accordion>
        );
      })}

      {!hasLicencesInitial && !hasLicences && (
        <Box sx={{ px: 4, pb: 4 }}>
          <EmptyState type="licenses" />
        </Box>
      )}

      <LicensesDetailsModal handleClose={handleCloseLicenseModal} open={isModalOpen} data={selectedRowData} />
      <RenewLicence
        handleClose={handleCloseLicenseRenew}
        open={isModalRenew}
        licenseData={licenseDataModal}
        organizationId={organizationId}
      />

      <Formik
        initialValues={{
          ...newLicenseValues,
          ...editLicense
        }}
        validationSchema={AddLicensesValidation}
        onSubmit={async _newLicense => {
          if (_newLicense?.subscription_uuid) {
            handleSubmitEditLicense(_newLicense);
            return;
          }
          handleSubmitLicense(_newLicense);
        }}
        enableReinitialize
      >
        <LicenseModal
          open={hasLicenseToEdit || showModal}
          handleClose={() => {
            if (hasLicenseToEdit) {
              setHasLicenseToEdit(false);
              setEditLicense({});
              return;
            }
            setShowModal(false);
          }}
        />
      </Formik>
    </Card>
  );
};

ConnectedLicensesContent.propTypes = {
  customer: T.shape({ licenses: T.arrayOf(T.shape({})) }).isRequired,
  data: T.instanceOf(Object).isRequired,
  loadingDataByResume: T.shape({}).isRequired,
  dataByResume: T.instanceOf(Object).isRequired,
  selectedUUID: T.string.isRequired,
  setSelectedUUID: T.func.isRequired,
  organizationId: T.string.isRequired
};

export default withLoadingState(ConnectedLicensesContent);
