import React, { useEffect, useRef } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PropTypes from 'prop-types';
import { fileToBase64 } from 'helpers/fileConverter';
import { API_URL } from 'attrs/settings';
import axios from '../../api/axios';

export default function Editor({ onChange }) {
  const editorRef = useRef();

  function uploadAdapter(loader) {
    return {
      upload: () =>
        new Promise(resolve => {
          loader.file.then(async file => {
            fileToBase64(file).then(base64String => {
              const formData = new FormData();
              formData.append('image', base64String);
              return axios
                .post('/preventive/v1/fs', formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then(response => {
                  resolve({
                    default: `${API_URL}/preventive/v1/p/fs/${response.data.url}`
                  });
                });
            });
          });
        })
    };
  }

  function uploadPlugin(edt) {
    const editor = edt;
    editor.plugins.get('FileRepository').createUploadAdapter = loader => uploadAdapter(loader);
  }

  useEffect(() => {
    ClassicEditor.create(editorRef.current, {
      toolbar: {
        items: [
          'heading',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'outdent',
          'indent',
          'imageUpload',
          'blockQuote',
          'insertTable',
          'mediaEmbed',
          'undo',
          'redo'
        ]
      },
      resize: {
        height: 300
      },
      extraPlugins: [uploadPlugin]
    })
      .then(editor => {
        editor.model.document.on('change:data', () => {
          const content = editor.getData();
          onChange(content);
        });

        const toolbarContainer = editor.ui.view.toolbar.element;
        const editorElement = editor.ui.view.editable.element;
        editor.editing.view.document.on('change:isFocused', (_evt, _data, isFocused) => {
          if (isFocused) {
            toolbarContainer.style.borderTop = '2px solid #2BACA4';
            editorElement.style.border = '1px solid #C4C4C4';
          } else {
            toolbarContainer.style.borderTop = '2px solid #C4C4C4';
          }
        });

        toolbarContainer.classList.add('custom-toolbar');
        toolbarContainer.style.position = 'absolute';
        toolbarContainer.style.width = '100%';
        toolbarContainer.style.bottom = '-50px';
        toolbarContainer.style.minHeight = '50px';
        toolbarContainer.style.background = '#EEEEF5';
        toolbarContainer.style.borderTop = '2px solid #C4C4C4';
        toolbarContainer.style.borderBottom = '1px solid #C4C4C4';

        editorElement.style.minHeight = '300px';
        editorElement.style.fontSize = '16px';

        const observer = new MutationObserver(() => {
          editorElement.style.height = '300px';
          editorElement.style.fontSize = '16px';
        });

        const observerOptions = {
          attributes: true,
          childList: true,
          subtree: true
        };

        observer.observe(editorElement, observerOptions);

        const buttonGroup = toolbarContainer.getElementsByClassName('ck-button-group')[0];
        const buttons = buttonGroup.getElementsByClassName('ck-button');

        for (let i = 0; i < buttons.length; i++) {
          const button = buttons[i];
          const commandName = button.getAttribute('data-command-name');

          if (
            commandName !== 'bold' &&
            commandName !== 'italic' &&
            commandName !== 'underline' &&
            commandName !== 'numberedList' &&
            commandName !== 'bulletedList'
          ) {
            button.style.display = 'none';
          }
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, []);

  return (
    <div style={{ minHeight: '300px', marginBottom: '50px' }}>
      <div ref={editorRef} />
    </div>
  );
}

Editor.propTypes = {
  onChange: PropTypes.func.isRequired
};
