import { StyledHandlers, StyledNewDialog } from './Gateways.styled';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, Stack } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { loadGatewaysVersions } from 'redux/gateways/actions';
import { getGatewayVersions, getGatewayVersionsState } from 'redux/gateways/selectors';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gatewaysV2GetGatewayVersionsByIdAction, gatewaysV2UpdateBySystemAction } from 'redux/gateways_v2/actions';
import { isSuccess } from 'helpers/utils';
import { actionStatus } from 'redux/utils';
import { getGatewaysV2 } from '../../redux/rootSelectors';

const operationalSystemEnum = {
  WINDOWS: 'WINDOWS',
  LINUX: 'LINUX',
  IRIS_V3: 'IRIS_V3'
};

const UpdateGatewaysModal = ({ open, handleClose }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const defaultOption = 'default';
  const [operationalSystem, setOperationalSystem] = useState(operationalSystemEnum.WINDOWS);
  const [version, setVersion] = useState(defaultOption);

  const loadingVersionListEqual = (oldObj, newObj) => JSON.stringify(oldObj || '') === JSON.stringify(newObj || '');
  const versionList = useSelector(getGatewayVersions, loadingVersionListEqual);

  const { gatewayVersionsByGtwId: versionListIrisV3 } = useSelector(getGatewaysV2);

  const loadingVersionListStateEqual = (oldObj, newObj) =>
    (oldObj?.loadingState?.status || false) === newObj?.loadingState?.status || false;

  const { loadingState: loadingVersionListState } = useSelector(getGatewayVersionsState, loadingVersionListStateEqual);

  const [versionByTypeList, setVersionByTypeList] = useState([]);

  const irisV3GatewayIdExample = 'WV3C68616E2C';

  useEffect(() => {
    if (isSuccess(loadingVersionListState.status)) {
      const tempVersions = versionList[operationalSystem.toLowerCase()];
      if (tempVersions && tempVersions.length > 0) {
        setVersionByTypeList(tempVersions);
      }
    }
  }, [dispatch, versionList, loadingVersionListState.status]);

  useEffect(() => {
    dispatch(loadGatewaysVersions({ type: '' }));
    dispatch(gatewaysV2GetGatewayVersionsByIdAction({ gatewayId: irisV3GatewayIdExample }));
  }, [dispatch, operationalSystem]);

  useEffect(() => {
    if (open === true) {
      setVersion(defaultOption);
      setOperationalSystem(operationalSystemEnum.WINDOWS);
    }
  }, [open]);

  const handleUpdateGateways = () => {
    dispatch(
      gatewaysV2UpdateBySystemAction({
        operational_system: operationalSystem,
        version: version
      })
    );
    handleClose();
  };

  const handleChangeRadioOperationalSystem = event => {
    setOperationalSystem(event.target.value);
    setVersion(defaultOption);
  };

  const handleChangeVersion = event => {
    setVersion(event.target.value);
  };

  const versionListToDisplay =
    operationalSystem === operationalSystemEnum.IRIS_V3 ? versionListIrisV3['iris_v3'] : versionByTypeList;

  const actionBar = (
    <StyledHandlers>
      <Button color="secondary" onClick={handleClose}>
        {t('default_actions.cancel')}
      </Button>
      <Button
        variant="contained"
        data-selector="license-modal-submit"
        onClick={handleUpdateGateways}
        style={{ padding: '1rem 2.5rem' }}
        disabled={version === defaultOption}
      >
        {t('gateways.update_all_gateways.update_selected')}
      </Button>
    </StyledHandlers>
  );
  return (
    <StyledNewDialog
      open={open}
      handleClose={handleClose}
      title={t('gateways.update_all_gateways.title')}
      subTitle={t('gateways.update_all_gateways.subtitle')}
      displayActionBar="true"
      actionBar={actionBar}
      maxWidth="1062px"
      content={
        <>
          <Grid item xs={6} sx={{ paddingLeft: '0px !important', marginBottom: '1em' }}>
            <RadioGroup
              row
              aria-labelledby="operational-system-label"
              name="operational-system"
              value={operationalSystem}
              onChange={handleChangeRadioOperationalSystem}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <FormControlLabel
                  value={operationalSystemEnum.WINDOWS}
                  control={<Radio />}
                  style={{ marginRight: '3em' }}
                  label={
                    <div style={{ position: 'relative' }}>
                      <span>Windows</span>
                      <span
                        style={{ position: 'absolute', left: '0', top: '1.2em', color: 'gray', whiteSpace: 'nowrap' }}
                      >
                        {versionList['windows'] &&
                          `${t('gateways.update_all_gateways.latest')} ${versionList['windows'][0]}`}
                      </span>
                    </div>
                  }
                />
                <FormControlLabel
                  value={operationalSystemEnum.LINUX}
                  control={<Radio />}
                  style={{ marginRight: '3em' }}
                  label={
                    <div style={{ position: 'relative' }}>
                      <span>Linux</span>
                      <span
                        style={{ position: 'absolute', left: '0', top: '1.2em', color: 'gray', whiteSpace: 'nowrap' }}
                      >
                        {versionList['linux'] &&
                          `${t('gateways.update_all_gateways.latest')} ${versionList['linux'][0]}`}
                      </span>
                    </div>
                  }
                />
                <FormControlLabel
                  value={operationalSystemEnum.IRIS_V3}
                  control={<Radio />}
                  label={
                    <div style={{ position: 'relative' }}>
                      <span>IRIS V3</span>
                      <span
                        style={{ position: 'absolute', left: '0', top: '1.2em', color: 'gray', whiteSpace: 'nowrap' }}
                      >
                        {versionListIrisV3['iris_v3'] &&
                          `${t('gateways.update_all_gateways.latest')} ${versionListIrisV3['iris_v3'][0]}`}
                      </span>
                    </div>
                  }
                />
              </Stack>
            </RadioGroup>
          </Grid>

          <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
            <FormControl variant="filled" required fullWidth>
              <Select
                fullWidth
                IconComponent={KeyboardArrowDownIcon}
                labelId="version-label"
                id="version-select"
                onChange={handleChangeVersion}
                value={version}
                disabled={loadingVersionListState.status !== actionStatus.SUCCESS}
              >
                <MenuItem value={defaultOption} disabled sx={{ minHeight: '36px !important' }}>
                  {t('gateways.update_all_gateways.select_operational_system')}
                </MenuItem>
                {versionListToDisplay?.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      }
    />
  );
};

UpdateGatewaysModal.propTypes = {
  open: T.bool.isRequired,
  handleClose: T.func.isRequired,
  gatewayId: T.string.isRequired,
  serialNumber: T.string,
  type: T.string,
  desiredVersion: T.string
};

export default UpdateGatewaysModal;
