import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardHeader } from '@mui/material';
import { NotifyIcon, NotifyTypo } from 'web-components';
import Tooltip from '@mui/material/Tooltip';
import EmptyStatePage from '../../../../../components/EmptyState/EmptyStatePage';
import AddSensor from '../AddSensor';
import PreDefinedSensor from '../PreDefinedSensor/PreDefinedSensor';
import SensorMap from '../SensorMap/SensorMap';
import CustomSensor from '../CustomSensor/CustomSensor';
import { PlcType } from '../../../../../attrs/plcConfigValues';
import IrisSensor from '../IrisSensor';

interface IrisSensorInterface {
  created_at: string;
  created_by: {
    email: string;
    first_name: string;
    id: string;
    last_name: string;
  };
  decimal_places: string;
  engineering_unit: {
    max: {
      value: number;
    };
    min: {
      value: number;
    };
  };
  high_trigger: boolean;
  icon_type: string;
  id: number;
  interval: number;
  is_active: boolean;
  last_updated_by: {
    email: string;
    first_name: string;
    id: string;
    last_name: string;
  };
  low_trigger: boolean;
  multiplication_factor: number;
  name: string;
  type: string;
  unit: string;
  updated_at: string;
  variable_name: string;
}

export interface PreDefinedSensorInterface {
  is_active: boolean;
  is_custom: boolean;
  is_static: boolean;
  period: string;
  pre_def_sensor_id: number;
  range: {
    max: {
      value?: number;
      source?: string;
    };
    min: {
      value?: number;
      source?: string;
    };
  };
  source: string;
  type: string;
  units: string;
}

interface CustomSensorInterface {
  custom_icon_type: string;
  custom_period: string;
  custom_sensor_id: number;
  custom_unit: string;
  decimal_place: string;
  high_trigger: boolean;
  is_active: boolean;
  is_custom: boolean;
  is_static: boolean;
  low_trigger: boolean;
  multiplication_factor: number;
  name: string;
  period: string;
  range: {
    max: {
      value: number;
    };
    min: {
      value: number;
    };
  };
  source: string;
  units: string;
  y_axis: {
    max: {
      specified: boolean;
      value: number;
    };
    min: {
      specified: boolean;
      value: number;
    };
  };
}

interface SensorListProps {
  machineId: string;
  plcType: {
    plc_type: string;
    autoconfig_enabled: boolean;
  };
  data: {
    machineId?: string;
    selection?: string[];
    sensors: {
      custom?: CustomSensorInterface[];
      pre_def?: PreDefinedSensorInterface[];
      static?: {
        custom_icon_type: string;
        custom_period: string;
        custom_unit: string;
        decimal_place: string;
        is_active: boolean;
        is_custom: boolean;
        is_static: boolean;
        multiplication_factor: number;
        name: string;
        period: string;
        source: string;
        static_sensor_id: number;
        units: string;
        value_type: string;
      }[];
      iris: IrisSensorInterface[];
    };
  };
}

const labelAmend = 'machines.machine_details';

const SensorList = ({ machineId, plcType, data }: SensorListProps) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const [preDefinedModal, setPreDefinedModal] = useState(false);
  const [preDefItem, setPreDefItem] = useState<PreDefinedSensorInterface | null>(null);

  const [customModal, setCustomModal] = useState(false);
  const [customItem, setCustomItem] = useState<CustomSensorInterface | null>(null);

  const [irisModal, setIrisModal] = useState(false);
  const [irisItem, setIrisItem] = useState<IrisSensorInterface | null>(null);

  const editIrisModal = (item: IrisSensorInterface) => {
    setIrisItem(item);
    setIrisModal(true);
  };

  const editCustomModal = (item: CustomSensorInterface) => {
    setCustomItem(item);
    setCustomModal(true);
  };

  const editPreDefSensorModal = (item: PreDefinedSensorInterface) => {
    setPreDefItem(item);
    setPreDefinedModal(true);
  };

  useEffect(() => {
    if (!customModal) {
      setCustomItem(null);
    }
    if (!preDefinedModal) {
      setPreDefItem(null);
    }
    if (!irisModal) {
      setIrisItem(null);
    }
  }, [customModal, preDefinedModal, irisModal]);

  const [hasSensorMachinesInitial, setHasSensorMachinesInitial] = useState(false);

  useEffect(() => {
    if (
      data &&
      data.sensors &&
      ((data.sensors.custom && data.sensors.custom?.length > 0) ||
        (data.sensors.pre_def && data.sensors.pre_def?.length > 0) ||
        data.sensors.iris?.length > 0)
    ) {
      setHasSensorMachinesInitial(true);
    }
  }, [data]);

  const [hasSensorMachines] = useState(hasSensorMachinesInitial);
  const handleClose = () => setEditModal(false);

  const handlePreDefinedModal = () => {
    setEditModal(false);
    setPreDefinedModal(true);
  };

  const handleCustomModal = () => {
    setEditModal(false);
    setCustomModal(true);
  };

  const handleIrisModal = () => {
    setIrisModal(true);
  };

  const handleClosePreDefinedModal = () => setPreDefinedModal(false);
  const handleCloseCustomModal = () => setCustomModal(false);
  const handleCloseIrisModal = () => setIrisModal(false);

  return (
    <Card sx={{ p: 4, my: 2, minHeight: '490px' }}>
      <CardHeader
        sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
        action={
          <Tooltip
            title={t(`machines.detail.plc_not_filled`)}
            disableHoverListener={plcType.plc_type !== PlcType.UNKNOWN}
            arrow
          >
            <span>
              <Button
                variant="outlined"
                disabled={plcType.plc_type === PlcType.UNKNOWN}
                sx={{ p: 1.5, gap: 1, display: 'flex', height: '55px' }}
                onClick={() => {
                  if (plcType.plc_type === PlcType.IRIS_V3) {
                    handleIrisModal();
                  } else {
                    setEditModal(true);
                  }
                }}
              >
                <NotifyIcon iconName="plus" fontSize="medium" />
                {t(`${labelAmend}.sensor_data_action`)}
              </Button>
            </span>
          </Tooltip>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.sensor_data`)}</Box>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: '500',
          variant: 'body1'
        }}
      />
      <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.sensor_data_info`)}</NotifyTypo.Subtitle1>
      {!hasSensorMachinesInitial && !hasSensorMachines && (
        <Box sx={{ mt: 3, mb: 4, pt: 4 }}>
          <EmptyStatePage type="sensor_data" />
        </Box>
      )}

      {hasSensorMachinesInitial && (
        <Box sx={{ mt: 3, mb: 4, pt: 4 }}>
          <SensorMap
            sensors={data}
            machineId={machineId}
            editCustomModal={editCustomModal}
            editPreDefinedModal={editPreDefSensorModal}
            editIrisModal={editIrisModal}
          />
        </Box>
      )}
      <AddSensor
        open={editModal}
        handleClose={handleClose}
        handlePreDefinedSensor={() => handlePreDefinedModal()}
        handleCustomSensor={() => handleCustomModal()}
      />
      <PreDefinedSensor
        handleClose={handleClosePreDefinedModal}
        open={preDefinedModal}
        machineId={machineId}
        sensorList={data?.sensors?.pre_def || []}
        preDefItem={preDefItem}
      />
      <CustomSensor
        handleClose={handleCloseCustomModal}
        open={customModal}
        machineId={machineId}
        customItem={customItem}
      />
      <IrisSensor handleClose={handleCloseIrisModal} open={irisModal} machineId={machineId} irisData={irisItem} />
    </Card>
  );
};

export default SensorList;
