import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PageHeader, useResolutionCheck } from 'web-components';

import MachineTable from '../../components/MachineTable';
import MachineCreateWrapper from '../../components/forms/Machines/MachineCreateWrapper';
import { ContainerHeader, ContentWrapper } from '../../elements';
import withLoadingState from '../../helpers/withLoadingState';

const Machines = ({ machines }) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useResolutionCheck();

  const tableColumns = [
    { id: 'commission_number', label: t('machines.overview.commission_number'), sortable: true },
    { id: 'brand', label: t('machines.overview.manufacturer'), sortable: false },
    { id: 'type', label: t('machines.overview.type'), sortable: false },
    { id: 'organization_name', label: t('machines.overview.customer'), sortable: false },
    { id: 'gateway_id', label: t('machines.overview.gateway_id'), sortable: false }
  ];

  return (
    <>
      <ContainerHeader>
        <PageHeader.Title>
          <PageHeader.Title.Main>{t('machines.title')}</PageHeader.Title.Main>
          <PageHeader.Title.Sub>{t('machines.subtitle')}</PageHeader.Title.Sub>
        </PageHeader.Title>
      </ContainerHeader>
      <ContentWrapper
        className={!isMobile && 'withPageHeader'}
        style={isDesktop ? { paddingLeft: '5rem', paddingRight: '5rem', paddingTop: 0 } : {}}
        maxWidth={false}
      >
        <MachineTable columns={tableColumns} rows={machines} />
      </ContentWrapper>
      {isMobile && <MachineCreateWrapper />}
    </>
  );
};

Machines.propTypes = {
  machines: T.shape({
    data: T.instanceOf(Array),
    limit: T.number,
    order: T.string,
    page: T.number,
    search: T.string,
    sort: T.instanceOf(Array),
    total_length: T.number,
    total_pages: T.number
  }).isRequired
};

export default withLoadingState(Machines);
