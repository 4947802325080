export const MACHINE_DEFAULT_LIST_SORT = 'commission_number';
export const MACHINE_DEFAULT_LIST_ORDER = 'desc';
export const ROUTE_MACHINES = 'machines';

export const IrisTableNameEnum = {
  BATCH_DETAILS_TABLE_NAME: 'BATCH_DETAILS_TABLE_NAME',
  BATCH_MEASUREMENTS_TABLE_NAME: 'BATCH_MEASUREMENTS_TABLE_NAME',
  MESSAGES_TABLE_NAME: 'MESSAGES_TABLE_NAME',
  METRICS_TABLE_NAME: 'METRICS_TABLE_NAME',
  UNITS_TABLE_NAME: 'UNITS_TABLE_NAME'
};

export const getIrisTableNameDetailsByType = (type, values) => {
  switch (type) {
    case IrisTableNameEnum.BATCH_DETAILS_TABLE_NAME:
      return {
        title: 'database.batch_details_title',
        subtitle: 'database.batch_details_subtitle',
        database: values?.batch_details_table_name,
        hasWarning: false,
        type
      };
    case IrisTableNameEnum.BATCH_MEASUREMENTS_TABLE_NAME:
      return {
        title: 'database.batch_measurements_title',
        subtitle: 'database.batch_measurements_subtitle',
        database: values?.batch_measurements_table_name,
        hasWarning: false,
        type
      };
    case IrisTableNameEnum.MESSAGES_TABLE_NAME:
      return {
        title: 'database.messages_title',
        subtitle: 'database.messages_subtitle',
        database: values?.messages_table_name,
        hasWarning: false,
        type
      };
    case IrisTableNameEnum.METRICS_TABLE_NAME:
      return {
        title: 'database.metrics_title',
        subtitle: 'database.metrics_subtitle',
        database: values?.metrics_table_name,
        hasWarning: true,
        type
      };
    case IrisTableNameEnum.UNITS_TABLE_NAME:
      return {
        title: 'database.units_title',
        subtitle: 'database.units_subtitle',
        database: values?.units_table_name,
        hasWarning: false,
        type
      };
    default:
      return {
        title: '',
        subtitle: '',
        database: '',
        hasWarning: false,
        type
      };
  }
};

export const BatchStatusEnum = {
  COMPLETED: 'COMPLETED',
  CONCLUDED: 'CONCLUDED',
  CANCELLED: 'CANCELLED',
  IN_OPERATION: 'IN_OPERATION',
  COMPLETED_BEFORE_TARGET: 'COMPLETED_BEFORE_TARGET',
  COMPLETED_WITH_TARGET_UPDATES: 'COMPLETED_WITH_TARGET_UPDATES',
  LOADING: 'LOADING',
  STOPPED: 'STOPPED'
};
