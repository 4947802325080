import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import CustomerDetail from './CustomerDetail';
import { loadOrganization } from '../../../redux/organizations/actions';
import { getSelectedOrganization } from '../../../redux/organizations/selectors';

const ConnectedCustomersContent = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loadingState, data } = useSelector(getSelectedOrganization);

  const loadNeededData = useCallback(() => {
    dispatch(loadOrganization(id));
  }, [dispatch, id]);

  useEffect(() => {
    loadNeededData();
  }, [loadNeededData]);

  return <CustomerDetail loadingState={loadingState} reloadAction={loadNeededData} customer={data} />;
};

export default ConnectedCustomersContent;
