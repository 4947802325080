import styled from 'styled-components';
import { NewDialog } from 'web-components';
import { Box } from '@mui/material';

const StyledNewDialog = styled(NewDialog)`
  .MuiDialog-paper {
    border-radius: 0.625rem;
    width: 100%;

    form {
      width: 100%;
    }
  }
  h2:first-of-type {
    padding-bottom: unset;
  }
`;

export const StyledHandlers = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export { StyledNewDialog };
