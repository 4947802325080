import React from 'react';
import T from 'prop-types';
import ConnectedMachinesContent from './ConnectedMachinesContent';
import { StyledContentWrapper } from '../CustomerDetail.styled';

const Machines = ({ customer }) => (
  <>
    <StyledContentWrapper elevation={3}>
      <ConnectedMachinesContent customer={customer} />
    </StyledContentWrapper>
  </>
);

Machines.propTypes = {
  customer: T.shape({ id: T.string, name: T.string }).isRequired
};

export default Machines;
