import { call, put, takeLatest } from 'redux-saga/effects';
import * as constants from './constants';
import { GATEWAYS_V2_MACHINE_BY_GTW_ID } from './constants';
import { actionStatus, getError, getErrorMessage, statusAction } from '../utils';
import { addNotification } from '../ui/notifications/actions';
import { generateId } from '../../helpers/utils';
import {
  apiGatewaysV2GetGatewayById,
  apiGatewayV2BindMachine,
  apiGatewayV2BindOrganization,
  apiGatewayV2GetLogsByGatewayId,
  apiGatewayV2GetMachineByGatewayId,
  apiGatewayV2GetMachineByGatewayIdAssignable,
  apiGatewayV2OrganizationGateways,
  apiGatewayV2UnbindMachine,
  apiGatewayV2UnbindOrganization,
  apiGatewaysV2UpdateBySystem,
  apiGatewaysV2GetGatewayVersionsByGatewayId
} from '../../api/gateways_v2';
import { LOAD_GATEWAYS_BY_ORGANIZATION } from '../gateways/constants';
import {
  PAGINATION_DEFAULT_ROWS_PER_PAGE,
  PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN,
  PAGINATION_DEFAULT_START_PAGE
} from '../../attrs/pagination';
import { GATEWAY_DEFAULT_LIST_ORDER, GATEWAY_DEFAULT_LIST_SORT } from '../../attrs/gateways';

/**
 *  Gateways V2 - Get gateway by ID
 *
 * @param {{
 *    gatewayId: string,
 *  }} params
 * @returns {Generator<*, void, *>}
 */
function* handlerGatewaysV2GetById({ gatewayId }) {
  yield put(statusAction(constants.GATEWAYS_V2_GET_BY_ID, actionStatus.START));

  try {
    const { data } = yield apiGatewaysV2GetGatewayById(gatewayId);
    yield put(statusAction(constants.GATEWAYS_V2_GET_BY_ID, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.GATEWAYS_V2_GET_BY_ID, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.gateways_v2.${error}`
      })
    );
  }
}

/**
 *  Gateways V2 - Get machines that belongs to the gateway
 * @param {string} gatewayId
 * @param {
 *  {
 *    commission_number: string|undefined,
 *    limit: number|5,
 *    page: number|1,
 *    order: 'desc'|'asc'|undefined,
 *    sort: ''|undefined
 *  }
 * } params
 * @returns {Generator<*, void, *>}
 */
function* handlerGatewaysV2GetMachinesByGtwId({ gatewayId, params }) {
  yield put(statusAction(constants.GATEWAYS_V2_MACHINE_BY_GTW_ID, actionStatus.START));

  try {
    const { data } = yield apiGatewayV2GetMachineByGatewayId(gatewayId, params);
    yield put(statusAction(constants.GATEWAYS_V2_MACHINE_BY_GTW_ID, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.GATEWAYS_V2_MACHINE_BY_GTW_ID, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.gateways_v2.${error}`
      })
    );
  }
}

/**
 * Gateways V2 - Bind organization to a gateway
 * @param {{
 *    gatewayId: string,
 *    organizationId: string
 *  }} params
 * @returns {Generator<*, void, *>}
 */
function* handlerGatewaysV2BindOrganization({ gatewayId, organizationId }) {
  yield put(statusAction(constants.GATEWAYS_V2_BIND_ORGANIZATION, actionStatus.START));

  try {
    const { data } = yield apiGatewayV2BindOrganization({
      gatewayId,
      organizationId
    });
    yield put(statusAction(constants.GATEWAYS_V2_BIND_ORGANIZATION, actionStatus.SUCCESS, { payload: data }));

    yield put({
      type: LOAD_GATEWAYS_BY_ORGANIZATION,
      organizationId,
      limit: PAGINATION_DEFAULT_ROWS_PER_PAGE,
      page: PAGINATION_DEFAULT_START_PAGE,
      order: GATEWAY_DEFAULT_LIST_ORDER,
      sort: GATEWAY_DEFAULT_LIST_SORT
    });

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: `constant_messages.gateway_v2.success.${data.message}`
      })
    );
  } catch (err) {
    const error = getErrorMessage(err);
    yield put(
      statusAction(constants.GATEWAYS_V2_BIND_ORGANIZATION, actionStatus.ERROR, {
        message: error.message
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `constant_messages.gateway_v2.error.${error.message}`
      })
    );
  }
}

/**
 * Gateways V2 - Unbind organization from a gateway
 * @param {{
 *    gatewayId: string,
 *    organizationId: string
 *  }} params
 * @returns {Generator<*, void, *>}
 */
function* handlerGatewaysV2UnbindOrganization({ gatewayId, organizationId }) {
  yield put(statusAction(constants.GATEWAYS_V2_UNBIND_ORGANIZATION, actionStatus.START));
  try {
    const { data } = yield apiGatewayV2UnbindOrganization({
      gatewayId,
      organizationId
    });

    yield put({
      type: LOAD_GATEWAYS_BY_ORGANIZATION,
      organizationId,
      limit: PAGINATION_DEFAULT_ROWS_PER_PAGE,
      page: PAGINATION_DEFAULT_START_PAGE,
      order: GATEWAY_DEFAULT_LIST_ORDER,
      sort: GATEWAY_DEFAULT_LIST_SORT
    });

    yield put(statusAction(constants.GATEWAYS_V2_UNBIND_ORGANIZATION, actionStatus.SUCCESS, { payload: data }));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: `constant_messages.gateway_v2.success.${data.message}`
      })
    );
  } catch (err) {
    const error = getErrorMessage(err);
    yield put(
      statusAction(constants.GATEWAYS_V2_UNBIND_ORGANIZATION, actionStatus.ERROR, {
        message: error.message
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `constant_messages.gateway_v2.error.${error.message}`
      })
    );
  }
}

/**
 * Gateways V2 - List Organization Gateways
 * @param {string} organizationId
 * @param {
 *   {
 *     serial_number: string|undefined,
 *     configured: string|undefined,
 *     limit: number|10,
 *     page: number|1,
 *     order: 'DESC'|'ASC'|undefined,
 *     sort: 'serial_number'|'last_seen'|undefined
 *   }
 * } params
 * @returns {Generator<*, void, *>}
 */
function* handlerGatewaysV2OrganizationGateways({ organizationId, params }) {
  yield put(statusAction(constants.GATEWAYS_V2_ORGANIZATION_GATEWAYS, actionStatus.START));
  try {
    const { data } = yield apiGatewayV2OrganizationGateways({
      organizationId,
      params
    });
    yield put(statusAction(constants.GATEWAYS_V2_ORGANIZATION_GATEWAYS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getErrorMessage(err);
    yield put(
      statusAction(constants.GATEWAYS_V2_ORGANIZATION_GATEWAYS, actionStatus.ERROR, {
        message: error.message
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `constant_messages.gateway_v2.error.${error.message}`
      })
    );
  }
}

/**
 * Gateways V2 - Bind machine to a gateway
 * @param {{
 *    gatewayId: string,
 *    machineId: string,
 *    reloadMachine: boolean,
 *    reloadResumed: boolean
 *  }} params
 * @returns {Generator<*, void, *>}
 */
function* handlerGatewaysV2BindMachine({ gatewayId, machineId, reloadMachine, reloadResumed }) {
  yield put(statusAction(constants.GATEWAYS_V2_BIND_MACHINE, actionStatus.START));

  try {
    const { data } = yield call(apiGatewayV2BindMachine, { gatewayId, machineId });
    yield put(statusAction(constants.GATEWAYS_V2_BIND_MACHINE, actionStatus.SUCCESS, { payload: data }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: `constant_messages.gateway_v2.success.${data.message}`
      })
    );

    // If you reload machine data, all page will be reloaded any way
    // and we need to reload the machine data do get the new gateway
    if (reloadMachine) {
      yield call(() => window.location.reload());
    }
    if (reloadResumed) {
      yield put({
        type: GATEWAYS_V2_MACHINE_BY_GTW_ID,
        gatewayId,
        params: {
          limit: PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN,
          page: PAGINATION_DEFAULT_START_PAGE
        }
      });
    }
  } catch (err) {
    const error = getErrorMessage(err);
    yield put(
      statusAction(constants.GATEWAYS_V2_BIND_MACHINE, actionStatus.ERROR, {
        message: error.message
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `constant_messages.gateway_v2.error.${error.message}`
      })
    );
  }
}

/**
 * Gateways V2 - Unbind Machine from a gateway
 * @param {{
 *    gatewayId: string,
 *    machineId: string,
 *    reloadMachines: boolean,
 *    reloadGtwMachines: boolean,
 *  }} params
 * @returns {Generator<*, void, *>}
 */
function* handlerGatewaysV2UnbindMachine({ gatewayId, machineId }) {
  yield put(statusAction(constants.GATEWAYS_V2_UNBIND_MACHINE, actionStatus.START));
  try {
    const { data } = yield call(apiGatewayV2UnbindMachine, { gatewayId, machineId });

    yield put(statusAction(constants.GATEWAYS_V2_UNBIND_MACHINE, actionStatus.SUCCESS, { payload: data }));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: `constant_messages.gateway_v2.success.${data.message}`
      })
    );

    // If you reload machine data, all page will be reloaded any way
    // and we need to reload the machine data do see if gateway was removed
    yield call(() => window.location.reload());
  } catch (err) {
    const error = getErrorMessage(err);
    yield put(
      statusAction(constants.GATEWAYS_V2_UNBIND_MACHINE, actionStatus.ERROR, {
        message: error.message
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `constant_messages.gateway_v2.error.${error.message}`
      })
    );
  }
}

/**
 * Gateways V2 - List machines assignable to a gateway
 * @param gatewayId
 * @param {
 *  {
 *    limit: number|5,
 *    page: number|1,
 *    order: 'desc'|'asc'|undefined,
 *    sort: 'commission_number'|'time'|undefined
 *  }
 * } params
 * @returns {Generator<AxiosPromise|*, void, *>}
 */
function* handlerGatewaysV2GetMachinesByGtwIdAssignable({ gatewayId, params }) {
  yield put(statusAction(constants.GATEWAYS_V2_LIST_MACH_ASSIGNED_TO_ORG_WITHOUT_GTW, actionStatus.START));

  try {
    const { data } = yield apiGatewayV2GetMachineByGatewayIdAssignable({
      gatewayId,
      params
    });
    yield put(
      statusAction(constants.GATEWAYS_V2_LIST_MACH_ASSIGNED_TO_ORG_WITHOUT_GTW, actionStatus.SUCCESS, { payload: data })
    );
  } catch (err) {
    const error = getErrorMessage(err);
    yield put(
      statusAction(constants.GATEWAYS_V2_LIST_MACH_ASSIGNED_TO_ORG_WITHOUT_GTW, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.gateways_v2.${error.message}`
      })
    );
  }
}

/**
 *  Gateways V2 - Get logs that belongs to the gateway
 * @param {string} gatewayId
 * @param {
 *  {
 *    limit: number|5,
 *    page: number|1,
 *    order: 'desc'|'asc'|undefined,
 *    sort: ''|undefined
 *  }
 * } params
 * @returns {Generator<*, void, *>}
 */
function* handlerGatewaysV2GetLogsByGtwId({ gatewayId, params }) {
  yield put(statusAction(constants.GATEWAYS_V2_LOGS, actionStatus.START));

  try {
    const { data: response } = yield apiGatewayV2GetLogsByGatewayId(gatewayId, params);
    const MAX_PAGES = 50;
    const passLimit = response?.total_pages > MAX_PAGES;
    const pagesAmount = passLimit ? MAX_PAGES : response?.total_pages || 0;
    const registerAmount = passLimit ? pagesAmount * (response?.limit || 1) : response?.total_length || 0;
    const newData = {
      page: response?.page || 0,
      total_pages: pagesAmount,
      total_length: registerAmount,
      limit: response?.limit || 0,
      data: response?.data || []
    };
    yield put(statusAction(constants.GATEWAYS_V2_LOGS, actionStatus.SUCCESS, { payload: newData }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.GATEWAYS_V2_LOGS, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.gateways_v2.${error}`
      })
    );
  }
}

/**
 * Gateways V2 - List machines assignable to a gateway
 * @param gatewayId
 * @param {
 *  {
 *    limit: number|5,
 *    page: number|1,
 *    order: 'desc'|'asc'|undefined,
 *    sort: 'commission_number'|'time'|undefined
 *  }
 * } params
 * @returns {Generator<AxiosPromise|*, void, *>}
 */
function* handlerGatewaysV2UpdateBySystem({ params }) {
  yield put(statusAction(constants.GATEWAYS_V2_UPDATE_BY_SYSTEM, actionStatus.START));

  try {
    const { data } = yield apiGatewaysV2UpdateBySystem({
      params
    });

    yield put(statusAction(constants.GATEWAYS_V2_UPDATE_BY_SYSTEM, actionStatus.SUCCESS, { payload: data }));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: `constant_messages.gateway_v2.success.UPDATE_ALL_GATEWAYS_VERSIONS`
      })
    );
  } catch (err) {
    const error = getErrorMessage(err);
    yield put(
      statusAction(constants.GATEWAYS_V2_UPDATE_BY_SYSTEM, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.gateways_v2.${error.message}`
      })
    );
  }
}

/**
 *  Gateways V2 - Get gateway versions by ID
 *
 * @param {{
 *    gatewayId: string,
 *  }} params
 * @returns {Generator<*, void, *>}
 */
function* handlerGatewaysV2GetGatewayVersionsById({ gatewayId }) {
  yield put(statusAction(constants.GATEWAYS_V2_GET_GATEWAY_VERSIONS_BY_GATEWAY_ID, actionStatus.START));

  try {
    const { data } = yield apiGatewaysV2GetGatewayVersionsByGatewayId(gatewayId);
    yield put(
      statusAction(constants.GATEWAYS_V2_GET_GATEWAY_VERSIONS_BY_GATEWAY_ID, actionStatus.SUCCESS, { payload: data })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.GATEWAYS_V2_GET_GATEWAY_VERSIONS_BY_GATEWAY_ID, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.GATEWAYS_V2_GET_BY_ID, handlerGatewaysV2GetById);
  yield takeLatest(constants.GATEWAYS_V2_MACHINE_BY_GTW_ID, handlerGatewaysV2GetMachinesByGtwId);
  yield takeLatest(constants.GATEWAYS_V2_BIND_ORGANIZATION, handlerGatewaysV2BindOrganization);
  yield takeLatest(constants.GATEWAYS_V2_UNBIND_ORGANIZATION, handlerGatewaysV2UnbindOrganization);
  yield takeLatest(constants.GATEWAYS_V2_ORGANIZATION_GATEWAYS, handlerGatewaysV2OrganizationGateways);
  yield takeLatest(constants.GATEWAYS_V2_BIND_MACHINE, handlerGatewaysV2BindMachine);
  yield takeLatest(constants.GATEWAYS_V2_UNBIND_MACHINE, handlerGatewaysV2UnbindMachine);
  yield takeLatest(
    constants.GATEWAYS_V2_LIST_MACH_ASSIGNED_TO_ORG_WITHOUT_GTW,
    handlerGatewaysV2GetMachinesByGtwIdAssignable
  );
  yield takeLatest(constants.GATEWAYS_V2_LOGS, handlerGatewaysV2GetLogsByGtwId);

  yield takeLatest(constants.GATEWAYS_V2_UPDATE_BY_SYSTEM, handlerGatewaysV2UpdateBySystem);

  yield takeLatest(constants.GATEWAYS_V2_GET_GATEWAY_VERSIONS_BY_GATEWAY_ID, handlerGatewaysV2GetGatewayVersionsById);
}
