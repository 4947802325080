import React from 'react';
import T from 'prop-types';
import withLoadingState from '../../../../helpers/withLoadingState';
import DatabaseTableName from '../DatabaseTableName';
import ManagementUnits from './ManagementUnits';
import { IrisTableNameEnum } from '../../../../attrs/machines';
import SensorList from './SensorList/SensorList';

const SensorDataContent = ({ data, machineId, plcType }) => {
  return (
    <>
      <DatabaseTableName
        plcType={plcType}
        machineId={machineId}
        selectedTableName={IrisTableNameEnum.UNITS_TABLE_NAME}
      />
      <ManagementUnits plcType={plcType} machineId={machineId} />
      <DatabaseTableName
        plcType={plcType}
        machineId={machineId}
        selectedTableName={IrisTableNameEnum.METRICS_TABLE_NAME}
      />
      <SensorList machineId={machineId} plcType={plcType} data={data} />
    </>
  );
};

SensorDataContent.propTypes = {
  data: T.shape({
    sensors: T.shape({
      custom: T.arrayOf(T.shape({})),
      pre_def: T.arrayOf(T.shape({})),
      static: T.arrayOf(T.shape({})),
      iris: T.arrayOf(T.shape({}))
    })
  }),
  machineId: T.string,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

SensorDataContent.defaultProps = {
  data: null,
  machineId: null
};

export default withLoadingState(SensorDataContent);
