import React, { useEffect, useState } from 'react';
import T from 'prop-types';

import { Chip, FormControl, Typography } from '@mui/material';
import { FormElements, NotifyTypo } from 'web-components';

import { CHECKBOX_SELECTED } from 'attrs/colors';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPlcProtocolName } from 'attrs/plcConfigValues';
import { getValueToNullableCell } from 'helpers/stringHandler';
import { loadCloneableMachinesDetails } from '../../../redux/cloneableMachines/actions';
import { getCloneableMachineDetail } from '../../../redux/rootSelectors';
import {
  InformationStepHeaderCommissionNumber,
  InformationStepHeaderItem,
  InformationStepHeaderName,
  InformationStepHeaderNameSpan,
  ListBox,
  ListBoxItem,
  StyledBox,
  StyledBoxContent,
  StyledBoxContentContainer,
  StyledBoxHeader,
  StyledWrapperCheck
} from './elements';

const labelAmmend = 'machines.overview.clone_modal.steps.information_step';

const CloneMachineInformationStep = ({ machineId, organizationId, checkboxValues, setCheckboxValues }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(getCloneableMachineDetail);
  const [allChecked, setAllChecked] = useState(false);
  const [alertPoints, setAlertPoints] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadCloneableMachinesDetails(machineId, organizationId));
      setLoading(false);
    }
    fetchData();
  }, [dispatch, machineId, organizationId]);

  useEffect(() => {
    const allValues = Object.values(checkboxValues);
    const allCheckedValues = allValues.every(item => item.checked === true);
    setAllChecked(allCheckedValues);
  }, [checkboxValues]);

  const mapSensor = {
    select_all: {
      type: '',
      name: 'select_all'
    },
    notify: {
      type: '',
      name: 'notify'
    },
    machine_detail: {
      type: '',
      name: 'machine'
    },
    monitoring_points: {
      type: '',
      name: 'monitoring_points'
    },
    batch_data_points: {
      type: 'BATCH',
      name: 'batch_data_points'
    },
    error_codes: {
      type: 'ERROR',
      name: 'error_codes'
    },
    sensor_data: {
      type: 'SENSOR_DATA',
      name: 'sensor_data'
    },
    static_sensor_data: {
      type: 'STATIC_DATA',
      name: 'static_sensor_data'
    },
    statuses: {
      type: 'STATUSES',
      name: 'statuses'
    },
    plc: {
      type: 'PLC',
      name: 'plc'
    },
    base_information: {
      type: 'DETAIL',
      name: 'base_information'
    },
    technical_information: {
      type: 'MECHANICAL_CONFIGURATION',
      name: 'technical_information'
    },
    feature_access: {
      type: 'FEATURE_ACCESS',
      name: 'feature_access'
    }
  };

  useEffect(() => {
    if (
      (data?.license_info?.sensor_data || 0) +
        (data?.license_info?.static_sensor_data || 0) +
        (data?.license_info?.statuses || 0) >
      (data?.license_info?.notify_license_data_points || 0)
    ) {
      setAlertPoints(true);
    } else {
      setAlertPoints(false);
    }
  }, [data]);

  const handleCheckboxChange = event => {
    const { name, checked } = event.target;

    if (name === 'select_all') {
      setAllChecked(checked);
      setCheckboxValues({
        select_all: {
          type: mapSensor.select_all.type,
          checked
        },
        machine_detail: {
          type: mapSensor.machine_detail.type,
          checked
        },
        base_information: {
          type: mapSensor.base_information.type,
          checked
        },
        technical_information: {
          type: mapSensor.technical_information.type,
          checked
        },
        feature_access: {
          type: mapSensor.feature_access.type,
          checked
        },
        notify: {
          type: mapSensor.notify.type,
          checked
        },
        plc: {
          type: mapSensor.plc.type,
          checked
        },
        monitoring_points: {
          type: mapSensor.monitoring_points.type,
          checked
        },
        sensor_data: {
          type: mapSensor.sensor_data.type,
          checked
        },
        static_sensor_data: {
          type: mapSensor.static_sensor_data.type,
          checked
        },
        statuses: {
          type: mapSensor.statuses.type,
          checked
        },
        error_codes: {
          type: mapSensor.error_codes.type,
          checked
        },
        batch_data_points: {
          type: mapSensor.batch_data_points.type,
          checked
        }
      });
    } else if (name === 'machine_detail') {
      setCheckboxValues({
        ...checkboxValues,
        [name]: {
          type: mapSensor.machine_detail.type,
          checked
        },
        base_information: {
          type: mapSensor.base_information.type,
          checked
        },
        technical_information: {
          type: mapSensor.technical_information.type,
          checked
        },
        feature_access: {
          type: mapSensor.feature_access.type,
          checked
        }
      });
    } else if (name === 'notify') {
      setCheckboxValues({
        ...checkboxValues,
        [name]: {
          type: mapSensor[name].type,
          checked
        },
        plc: {
          type: mapSensor.plc.type,
          checked
        },
        monitoring_points: {
          type: mapSensor.monitoring_points.type,
          checked
        },
        sensor_data: {
          type: mapSensor.sensor_data.type,
          checked
        },
        static_sensor_data: {
          type: mapSensor.static_sensor_data.type,
          checked
        },
        statuses: {
          type: mapSensor.statuses.type,
          checked
        },
        error_codes: {
          type: mapSensor.error_codes.type,
          checked
        },
        batch_data_points: {
          type: mapSensor.batch_data_points.type,
          checked
        }
      });
    } else if (name === 'monitoring_points') {
      setCheckboxValues({
        ...checkboxValues,
        [name]: {
          type: mapSensor[name].type,
          checked
        },
        sensor_data: {
          type: mapSensor.sensor_data.type,
          checked
        },
        static_sensor_data: {
          type: mapSensor.static_sensor_data.type,
          checked
        },
        statuses: {
          type: mapSensor.statuses.type,
          checked
        }
      });
    } else {
      setCheckboxValues({
        ...checkboxValues,
        [name]: {
          type: mapSensor[name].type,
          checked
        }
      });

      const allValues = Object.values({
        ...checkboxValues,
        [name]: {
          type: mapSensor[name].type,
          checked
        }
      });
      // eslint-disable-next-line no-shadow
      const allChecked = allValues.every(item => item.checked === true);

      setAllChecked(allChecked);
    }
  };

  return (
    <StyledBox test-id="test">
      {loading ? (
        <>Loading</>
      ) : (
        <>
          <StyledBoxHeader>
            <InformationStepHeaderItem>
              <InformationStepHeaderName>{getValueToNullableCell(data?.machine_name)}</InformationStepHeaderName>
            </InformationStepHeaderItem>
            <InformationStepHeaderItem>
              <InformationStepHeaderCommissionNumber>
                {getValueToNullableCell(data?.commission_number)}
              </InformationStepHeaderCommissionNumber>
            </InformationStepHeaderItem>
            <InformationStepHeaderItem>
              <InformationStepHeaderNameSpan>
                {getValueToNullableCell(data?.machine_type)}
              </InformationStepHeaderNameSpan>
            </InformationStepHeaderItem>
            <InformationStepHeaderItem>
              <InformationStepHeaderNameSpan>{getPlcProtocolName(data?.plc?.type)}</InformationStepHeaderNameSpan>
            </InformationStepHeaderItem>
            <InformationStepHeaderItem>
              <InformationStepHeaderNameSpan>
                {getValueToNullableCell(data?.customer_name)}
              </InformationStepHeaderNameSpan>
            </InformationStepHeaderItem>
          </StyledBoxHeader>

          <FormControl>
            <StyledBoxContent>
              <NotifyTypo.Body1 sx={{ fontWeight: 500, mb: 2.5 }}>{t(`${labelAmmend}.title`)}</NotifyTypo.Body1>
              <FormElements.Checkbox
                activeColor={CHECKBOX_SELECTED}
                name="select_all"
                label={<Typography sx={{ fontSize: 16 }}>{t(`${labelAmmend}.select_all`)}</Typography>}
                checked={allChecked}
                onChange={handleCheckboxChange}
              />
              <StyledBoxContentContainer>
                <ListBox>
                  <ListBoxItem>
                    <FormElements.Checkbox
                      activeColor={CHECKBOX_SELECTED}
                      name="machine_detail"
                      label={
                        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                          {t(`${labelAmmend}.machine_details`)}
                        </Typography>
                      }
                      checked={checkboxValues?.machine_detail?.checked}
                      onChange={handleCheckboxChange}
                    />
                  </ListBoxItem>
                  <ul>
                    <ListBoxItem>
                      <FormElements.Checkbox
                        activeColor={CHECKBOX_SELECTED}
                        name="base_information"
                        label={<Typography sx={{ fontSize: 16 }}>{t(`${labelAmmend}.basic_information`)}</Typography>}
                        checked={checkboxValues?.base_information?.checked}
                        onChange={handleCheckboxChange}
                      />
                    </ListBoxItem>
                    <ListBoxItem>
                      <FormElements.Checkbox
                        activeColor={CHECKBOX_SELECTED}
                        name="technical_information"
                        label={
                          <Typography sx={{ fontSize: 16 }}>{t(`${labelAmmend}.technical_information`)}</Typography>
                        }
                        checked={checkboxValues?.technical_information?.checked}
                        onChange={handleCheckboxChange}
                      />
                    </ListBoxItem>
                    <ListBoxItem>
                      <FormElements.Checkbox
                        activeColor={CHECKBOX_SELECTED}
                        name="feature_access"
                        label={<Typography sx={{ fontSize: 16 }}>{t(`${labelAmmend}.feature_access`)}</Typography>}
                        checked={checkboxValues?.feature_access?.checked}
                        onChange={handleCheckboxChange}
                      />
                    </ListBoxItem>
                  </ul>
                </ListBox>
                <ListBox>
                  <FormElements.Checkbox
                    activeColor={CHECKBOX_SELECTED}
                    name="notify"
                    label={
                      <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{t(`${labelAmmend}.notify`)}</Typography>
                    }
                    checked={checkboxValues?.notify?.checked}
                    onChange={handleCheckboxChange}
                  />
                  <ul>
                    <FormElements.Checkbox
                      activeColor={CHECKBOX_SELECTED}
                      name="plc"
                      label={<Typography sx={{ fontSize: 16 }}>{t(`${labelAmmend}.plc`)}</Typography>}
                      checked={checkboxValues?.plc?.checked}
                      onChange={handleCheckboxChange}
                    />
                    <StyledWrapperCheck>
                      <FormElements.Checkbox
                        activeColor={CHECKBOX_SELECTED}
                        name="monitoring_points"
                        label={<Typography sx={{ fontSize: 16 }}>{t(`${labelAmmend}.monitoring_points`)}</Typography>}
                        checked={checkboxValues?.monitoring_points?.checked}
                        onChange={handleCheckboxChange}
                      />
                      <Chip
                        label={`${
                          data?.license_info?.sensor_data +
                          data?.license_info?.static_sensor_data +
                          data?.license_info?.statuses
                        } / ${data?.license_info?.notify_license_data_points}`}
                        size="small"
                        sx={{ bgcolor: '#338D8580', color: alertPoints ? '#F13309' : '#FFFFFF' }}
                      />
                    </StyledWrapperCheck>
                    <ul>
                      <StyledWrapperCheck>
                        <FormElements.Checkbox
                          activeColor={CHECKBOX_SELECTED}
                          name="sensor_data"
                          label={<Typography sx={{ fontSize: 16 }}>{t(`${labelAmmend}.sensor_data`)}</Typography>}
                          checked={checkboxValues?.sensor_data?.checked}
                          onChange={handleCheckboxChange}
                        />
                        <Chip
                          label={data?.license_info?.sensor_data}
                          size="small"
                          sx={{ bgcolor: '#338D8580', color: alertPoints ? '#F13309' : '#FFFFFF' }}
                        />
                      </StyledWrapperCheck>

                      <StyledWrapperCheck>
                        <FormElements.Checkbox
                          activeColor={CHECKBOX_SELECTED}
                          name="static_sensor_data"
                          label={<Typography sx={{ fontSize: 16 }}>{t(`${labelAmmend}.static_values`)}</Typography>}
                          checked={checkboxValues?.static_sensor_data?.checked}
                          onChange={handleCheckboxChange}
                        />
                        <Chip
                          label={data?.license_info?.static_sensor_data}
                          size="small"
                          sx={{ bgcolor: '#338D8580', color: alertPoints ? '#F13309' : '#FFFFFF' }}
                        />
                      </StyledWrapperCheck>
                      <StyledWrapperCheck>
                        <FormElements.Checkbox
                          activeColor={CHECKBOX_SELECTED}
                          name="statuses"
                          label={<Typography sx={{ fontSize: 16 }}>{t(`${labelAmmend}.status`)}</Typography>}
                          checked={checkboxValues?.statuses?.checked}
                          onChange={handleCheckboxChange}
                        />
                        <Chip
                          label={data?.license_info?.statuses}
                          size="small"
                          sx={{ bgcolor: '#338D8580', color: alertPoints ? '#F13309' : '#FFFFFF' }}
                        />
                      </StyledWrapperCheck>
                    </ul>
                    <FormElements.Checkbox
                      activeColor={CHECKBOX_SELECTED}
                      name="error_codes"
                      label={<Typography sx={{ fontSize: 16 }}>{t(`${labelAmmend}.error_codes`)}</Typography>}
                      checked={checkboxValues?.error_codes?.checked}
                      onChange={handleCheckboxChange}
                    />
                    <FormElements.Checkbox
                      activeColor={CHECKBOX_SELECTED}
                      name="batch_data_points"
                      label={<Typography sx={{ fontSize: 16 }}>{t(`${labelAmmend}.batch_records`)}</Typography>}
                      checked={checkboxValues?.batch_data_points?.checked}
                      onChange={handleCheckboxChange}
                    />
                  </ul>
                </ListBox>
              </StyledBoxContentContainer>
            </StyledBoxContent>
          </FormControl>
        </>
      )}
    </StyledBox>
  );
};
CloneMachineInformationStep.propTypes = {
  machineId: T.string.isRequired,
  organizationId: T.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  checkboxValues: T.object.isRequired,
  setCheckboxValues: T.func.isRequired
};

export default CloneMachineInformationStep;
