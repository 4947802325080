import React from 'react';
import { useSelector } from 'react-redux';

import { getSelectedMachine } from 'redux/machines/selectors';
import GatewayContent from './GatewayContent';

const ConnectedGatewayContent = () => {
  const { loadingState, loadingStatePlcType, data, plcType } = useSelector(getSelectedMachine);

  return (
    <GatewayContent
      loadingState={loadingState}
      gateways={data}
      loadingStatePlcType={loadingStatePlcType}
      plcType={plcType}
    />
  );
};

export default ConnectedGatewayContent;
