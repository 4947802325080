import GatewayDetailsContainer from 'containers/Gateways/GatewayDetails/GatewayDetailsContainer';
import TaskStepper from '../containers/Machines/MachineDetail/Task/create/TaskStepper';
import Customers from '../containers/Customers';
import CustomerDetail from '../containers/Customers/CustomerDetail';
import CustomerCreate from '../containers/Customers/CustomerCreate';
import Gateways from '../containers/Gateways';
import Machines from '../containers/Machines';
import MachineDetail from '../containers/Machines/MachineDetail';
import { DashboardContainer } from '../containers/Dashboard';

const ROUTES = [
  {
    path: '/dashboard/:tab',
    component: DashboardContainer,
    name: 'dashboard_tab'
  },
  {
    path: '/dashboard',
    component: DashboardContainer,
    name: 'dashboard',
    topNav: false,
    subNav: {
      main: true
    }
  },
  {
    path: '/customers/new-customer',
    component: CustomerCreate,
    name: 'new-customer'
  },
  {
    path: '/customers',
    component: Customers,
    name: 'customers',
    topNav: false,
    subNav: {
      main: true
    }
  },
  {
    path: '/customers/:id',
    component: CustomerDetail,
    name: 'customer'
  },
  {
    path: '/gateways',
    component: Gateways,
    name: 'gateways',
    topNav: false,
    subNav: {
      main: true
    }
  },
  {
    path: '/gateways/:id',
    component: GatewayDetailsContainer,
    name: 'gateway'
  },
  {
    path: '/machines',
    component: Machines,
    name: 'machines',
    topNav: false,
    subNav: {
      main: true
    }
  },
  {
    path: '/machines/:machineId',
    component: MachineDetail,
    name: 'machine'
  },
  {
    path: '/customers/:customerId/machines/:machineId',
    component: MachineDetail,
    name: 'machine'
  },
  {
    path: '/customers/:customerId/machines/:machineId/:selectedTab',
    component: MachineDetail,
    name: 'machine'
  },
  {
    path: '/machines/:id/:selectedTab',
    component: MachineDetail,
    name: 'machine'
  },
  {
    path: '/customers/:customerId/machines/:machineId/tasks/create',
    component: TaskStepper,
    name: 'create_task'
  }
  // Account update/register account/reset password are not enabled by BE yet
  //
  // {
  //   path: '/account',
  //   component: Account,
  //   name: 'account',
  //   subNav: {
  //     main: false,
  //   },
  // },
  // {
  //   path: '/account/reset-password',
  //   component: ResetPassword,
  //   name: 'reset_password',
  // },
  // {
  //   path: '/register/:token',
  //   component: AlreadyLoggedIn,
  //   name: 'already_logged_in',
  // },
];

export default ROUTES;
