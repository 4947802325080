import { Box, Stack, Typography } from '@mui/material';
import EmptyState from 'components/EmptyState';
import { getValueToNullableCell } from 'helpers/stringHandler';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'web-components-ts';
import { useDispatch, useSelector } from 'react-redux';
import { gatewaysV2GetMachinesByGtwIdAction } from 'redux/gateways_v2/actions';
import { getMachineType, isSuccess } from 'helpers/utils';
import { getGatewaysV2 } from 'redux/rootSelectors';
import { useHistory } from 'react-router-dom';
import { GatewayMachineInterface, MachineStateInterface } from './types/machineStateInterface';

interface GatewayMachinesProps {
  gatewayId: string;
}

export default function GatewayMachines({ gatewayId }: GatewayMachinesProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [machineList, setMachineList] = useState<GatewayMachineInterface[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const rowsPerPage = 5;

  const loadingMachinesStateEqual = (oldObj: MachineStateInterface, newObj: MachineStateInterface) => {
    return (oldObj?.loadingMachinesState?.status || false) === newObj?.loadingMachinesState?.status || false;
  };

  const { loadingMachinesState } = useSelector(getGatewaysV2, loadingMachinesStateEqual);

  const machineListEqual = (oldObj: MachineStateInterface, newObj: MachineStateInterface) => {
    const sameSize = oldObj?.getGatewayMachineByGtwId?.data?.length === newObj?.getGatewayMachineByGtwId?.data?.length;
    let equalsObj = true;
    for (let i = 0; sameSize && i < oldObj?.getGatewayMachineByGtwId?.data?.length; i += 1) {
      equalsObj =
        JSON.stringify(oldObj?.getGatewayMachineByGtwId?.data[i] || '') ===
        JSON.stringify(newObj?.getGatewayMachineByGtwId?.data[i] || '');
      if (!equalsObj) break;
    }
    return (
      JSON.stringify(oldObj?.getGatewayMachineByGtwId?.data || '') ===
        JSON.stringify(newObj?.getGatewayMachineByGtwId?.data || '') &&
      oldObj?.getGatewayMachineByGtwId?.total_length === newObj?.getGatewayMachineByGtwId?.total_length &&
      sameSize &&
      equalsObj
    );
  };
  const { getGatewayMachineByGtwId: getMachinesResponse } = useSelector(getGatewaysV2, machineListEqual);

  useEffect(() => {
    if (gatewayId) {
      dispatch(
        gatewaysV2GetMachinesByGtwIdAction(gatewayId, {
          limit: rowsPerPage,
          page: currentPage
        })
      );
    }
  }, [dispatch, gatewaysV2GetMachinesByGtwIdAction, gatewayId]);

  useEffect(() => {
    if (isSuccess(loadingMachinesState.status)) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [loadingMachinesState.status]);

  useEffect(() => {
    if (getMachinesResponse && getMachinesResponse.data && getMachinesResponse.data.length > 0) {
      setCurrentPage(getMachinesResponse.page || 0);
      setTotalPages(getMachinesResponse.total_pages || 0);
      setMachineList(getMachinesResponse.data);
    }
  }, [getMachinesResponse]);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
    dispatch(
      gatewaysV2GetMachinesByGtwIdAction(gatewayId, {
        limit: rowsPerPage,
        page
      })
    );
  };

  const handleSelectRow = (row: GatewayMachineInterface) =>
    history.push(`/customers/${row.organization_id}/machines/${row.id}`);

  const gatewayMachinesTableColumns = [
    {
      field: 'brand',
      label: t('gateway_details.machines.brand'),
      sort: false,
      render: (row: GatewayMachineInterface) => getValueToNullableCell(row?.brand || row?.other_brand)
    },
    {
      field: 'name',
      label: t('gateway_details.machines.name'),
      sort: false,
      render: (row: GatewayMachineInterface) => getValueToNullableCell(row?.name)
    },
    {
      field: 'type',
      label: t('gateway_details.machines.type'),
      sort: false,
      render: (row: GatewayMachineInterface) =>
        row?.type ? getMachineType(row?.type).type : getValueToNullableCell(row?.other_type)
    },
    {
      field: 'comission_number',
      label: t('gateway_details.machines.comission_number'),
      sort: false,
      render: (row: GatewayMachineInterface) => getValueToNullableCell(row?.commission_number || row?.id_number)
    }
  ];

  const gatewayMachinesTableProps = {
    rowKey: 'id',
    dataSource: machineList,
    columns: gatewayMachinesTableColumns,
    rowClick: handleSelectRow,
    hasFooter: true,
    currentPage,
    totalPages,
    rowsPerPage,
    handleChangePage,
    itensPerPageLabel: t('general.overview.items'),
    itemLabel: t('general.overview.of')
  };

  return (
    <Box
      sx={{
        overflow: 'hidden',
        backgroundColor: 'white',
        boxShadow: 2,
        borderRadius: '8px',
        width: '100%',
        p: 3
      }}
      data-selector="gateway-machines"
    >
      <Stack direction="column" spacing={3}>
        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{t('gateway_details.machines.title')}</Typography>
        {(machineList && machineList.length > 0) || isLoading ? (
          <Table {...gatewayMachinesTableProps} />
        ) : (
          <Box sx={{ py: 2 }}>
            <EmptyState type="gtw_link_machines" />
          </Box>
        )}
      </Stack>
    </Box>
  );
}
