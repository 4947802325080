import { put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import compose from 'lodash/fp/compose';

import * as constants from './constants';
import { loadUser, resetPassword, updateUser } from '../../api/user';
import { actionStatus, getError, getUserInformationFromToken, statusAction } from '../utils';
import { generateId } from '../../helpers/utils';
import { addNotification } from '../ui/notifications/actions';
import * as actions from './actions';
import { getData, getUser } from '../rootSelectors';
import { getUserId, getUserInfo } from './selectors';
import i18n from '../../i18n';

function* handleLoadUser({ id }) {
  let userId = id;
  if (!userId) {
    userId = yield select(compose(getUserId, getData, getUserInfo, getUser));
  }

  if (userId) {
    yield put(statusAction(constants.LOAD_USER, actionStatus.START));

    try {
      const { data } = yield loadUser(userId);
      // change language based on user profile
      if (data && data.language && i18n.language !== data.language) {
        i18n.changeLanguage(data.language);
      }

      yield put(statusAction(constants.LOAD_USER, actionStatus.SUCCESS, { payload: data }));
    } catch (err) {
      const error = getError(err);
      yield put(
        statusAction(constants.LOAD_USER, actionStatus.ERROR, {
          message: error
        })
      );
      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.ERROR,
          message: `errors.users.${error}`
        })
      );
    }
  }
}

function* handleUpdateUser({ userId, values }) {
  yield put(statusAction(constants.UPDATE_USER, actionStatus.START));

  try {
    const { id, ...filteredValues } = values;
    const { data } = yield updateUser(userId, filteredValues);

    yield put(statusAction(constants.UPDATE_USER, actionStatus.SUCCESS, { payload: data }));
    yield put(push('/'));

    // change language based on user profile
    if (data && data.language && i18n.language !== data.language) {
      i18n.changeLanguage(data.language);
    }
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_USER, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.users.${error}`
      })
    );
  }
}

function* handleLoadUserInformation() {
  // should load current data from user and set to store
  const token = yield localStorage.getItem('accessToken');
  if (token) {
    const user = getUserInformationFromToken(token);

    yield put(actions.setUserInformation(user));
    yield put(actions.loadUser(user.id));
  }
}

function* handleResetPassword({ values }) {
  yield put(statusAction(constants.RESET_PASSWORD, actionStatus.START));
  try {
    yield resetPassword(values);

    yield put(statusAction(constants.RESET_PASSWORD, actionStatus.SUCCESS));
    yield put(push('/'));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.RESET_PASSWORD, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.users.${error}`
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.LOAD_USER_INFORMATION, handleLoadUserInformation);
  yield takeLatest(constants.LOAD_USER, handleLoadUser);
  yield takeLatest(constants.UPDATE_USER, handleUpdateUser);
  yield takeLatest(constants.RESET_PASSWORD, handleResetPassword);
}
