import React from 'react';
import { StyledContentWrapper } from '../../../../components/forms/Machines/MachineDetail/elements';
import ConnectedGatewayContent from './ConnectedGatewayContent';

const Gateway = () => (
  <StyledContentWrapper elevation={3}>
    <ConnectedGatewayContent />
  </StyledContentWrapper>
);

export default Gateway;
