import React from 'react';
import T from 'prop-types';
import { useResolutionCheck } from 'web-components';

import MachineTable from './MachineTable';
import MobileMachineTable from './MobileMachineTable';

const MachineTableSelector = ({ rows, columns, organizationId }) => {
  const { isTablet } = useResolutionCheck();

  return isTablet ? (
    <MobileMachineTable columns={columns} rows={rows} />
  ) : (
    <MachineTable columns={columns} rows={rows} organizationId={organizationId} />
  );
};

MachineTableSelector.propTypes = {
  columns: T.arrayOf(T.shape({})).isRequired,
  rows: T.instanceOf(Object).isRequired,
  organizationId: T.string
};

MachineTableSelector.defaultProps = {
  organizationId: null
};

export default MachineTableSelector;
