import { merge } from 'lodash/fp';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { SUCCESS, WARNING } from 'attrs/status';
import { push } from 'connected-react-router';
import { linkMachineByGateway } from 'api/gateways';
import * as api from '../../api/machines';
import { verifyCommissionNumber } from '../../api/machines';
import { STATUS_INVALID, STATUS_NOT_VERIFIED, STATUS_VALID } from '../../attrs/salesforce';
import initialMachineValues from '../../components/forms/Machines/initialMachineValues';
import { generateId } from '../../helpers/utils';
import { addNotification } from '../ui/notifications/actions';
import { actionStatus, getError, statusAction } from '../utils';
import { setMachineFilter, setMachineSortOrder } from './actions';
import * as constants from './constants';
import { getSelectedMachine, getVerificationCommissionNumber } from './selectors';
import { convertToValidMachineBackendObject } from './utils';

function* updateMachineGateway(gatewaySN, machineIds) {
  yield put(statusAction(constants.UPDATE_MACHINE, actionStatus.START));

  try {
    const validMachineObject = {
      gateway_id: gatewaySN,
      machine_id: machineIds
    };

    const { data } = yield linkMachineByGateway(gatewaySN, validMachineObject);
    yield put(statusAction(constants.UPDATE_MACHINE, actionStatus.SUCCESS, { payload: data }));

    return true;
  } catch {
    yield put(statusAction(constants.UPDATE_MACHINE, actionStatus.ERROR));
    return false;
  }
}

function* handleVerifyCommissionNumber(values) {
  yield put(
    statusAction(constants.VERIFY_COMMISSION_NUMBER_ID, actionStatus.START, {
      payload: {
        commission_number: values.commissionNumber || values.commission_number,
        commission_number_status: STATUS_NOT_VERIFIED
      }
    })
  );

  try {
    const { data } = yield call(verifyCommissionNumber, {
      commission_number: values.commissionNumber || values.commission_number
    });

    yield put(
      statusAction(constants.VERIFY_COMMISSION_NUMBER_ID, actionStatus.SUCCESS, {
        payload: {
          commission_number: data.commission_number,
          commission_number_status: data.is_exist ? STATUS_VALID : STATUS_INVALID
        }
      })
    );

    const message = data.is_exist
      ? 'machines.form.commission_number_verification_success'
      : 'machines.form.commission_number_verification_failed_short';

    const type = data.is_exist ? SUCCESS : WARNING;

    yield put(
      addNotification({
        key: generateId(),
        type,
        message
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.VERIFY_COMMISSION_NUMBER_ID, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* verifyCommissionNumberAndPrepareModifiedValues(values) {
  let verificationStatus = yield select(getVerificationCommissionNumber);
  const isCommissionNumberPopulated = Boolean(values.commission_number.length);
  const shouldVerifyCommissionNumber = verificationStatus === STATUS_NOT_VERIFIED && isCommissionNumberPopulated;

  if (shouldVerifyCommissionNumber) {
    yield call(handleVerifyCommissionNumber, values);
    verificationStatus = yield select(getVerificationCommissionNumber);
  }

  const commissionNumberStatus =
    verificationStatus && isCommissionNumberPopulated ? verificationStatus : STATUS_NOT_VERIFIED;

  const validMachineObject = convertToValidMachineBackendObject(values);

  return {
    ...validMachineObject,
    commission_number_status: commissionNumberStatus
  };
}

function* handleCreateNewMachine({ payload }) {
  yield put(statusAction(constants.CREATE_NEW_MACHINE, actionStatus.START));

  try {
    const { data } = yield call(api.createNewMachine, payload);
    yield put(statusAction(constants.CREATE_NEW_MACHINE, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.CREATE_NEW_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleCreateMachine({ values }) {
  yield put(statusAction(constants.CREATE_MACHINE, actionStatus.START));

  try {
    const modifiedValues = yield call(verifyCommissionNumberAndPrepareModifiedValues, values);
    const validMachineObject = convertToValidMachineBackendObject(modifiedValues);

    const { data } = yield api.createMachine(validMachineObject);
    yield put(statusAction(constants.CREATE_MACHINE, actionStatus.SUCCESS, { payload: data }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.CREATE_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateMachinesGateway({ gatewaySN, machineIds }) {
  try {
    yield updateMachineGateway(gatewaySN, machineIds);
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch {
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: 'errors.machines.gateway_update'
      })
    );
  }
}

function* handleUpdateMachine({ id, values }) {
  yield put(statusAction(constants.UPDATE_MACHINE, actionStatus.START));

  try {
    const modifiedValues = yield call(verifyCommissionNumberAndPrepareModifiedValues, values);
    const validMachineObject = convertToValidMachineBackendObject(modifiedValues);

    const { data } = yield api.updateMachine(id, validMachineObject);
    // merge with initial values to handle bad backend response
    const modData = merge(initialMachineValues, data);
    yield put(statusAction(constants.UPDATE_MACHINE, actionStatus.SUCCESS, { payload: modData }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateSensor({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_SENSOR, actionStatus.START));

  try {
    const { data } = yield api.updateSensor(machineId, payload);
    yield put(statusAction(constants.UPDATE_SENSOR, actionStatus.SUCCESS, { payload: data }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_SENSOR, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleLoadMachinesByResume({
  organizationId,
  organizationName,
  commissionNumber,
  gatewayId,
  limit,
  page,
  order,
  sort
}) {
  yield put(statusAction(constants.LOAD_MACHINES_RESUME, actionStatus.START));
  try {
    const { data } = yield api.loadMachinesByResume(
      organizationId,
      organizationName,
      commissionNumber,
      gatewayId,
      limit,
      page,
      order,
      sort
    );

    yield put(setMachineSortOrder(sort, order));
    yield put(setMachineFilter(organizationId, organizationName, commissionNumber));

    yield put(statusAction(constants.LOAD_MACHINES_RESUME, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_MACHINES_RESUME, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleFetchFiltered({ page, limit, order, sort, organizationName, commissionNumber, serialNumber }) {
  try {
    const params = {
      limit,
      page,
      order,
      sort
    };

    if (organizationName !== '') {
      params.organization_name = organizationName;
    }

    if (commissionNumber !== '') {
      params.commission_number = commissionNumber;
    }

    if (serialNumber !== '') {
      params.serial_number = serialNumber;
    }

    const { data } = yield api.loadMachinesResumedSearch(params);

    yield put(setMachineSortOrder(sort, order));
    yield put(
      statusAction(constants.LOAD_MACHINE_RESUMED, actionStatus.SUCCESS, {
        payload: data
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_MACHINE_RESUMED, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.alerts.${error}`
      })
    );
  }
}

function* handleLoadMachines({ organizationId }) {
  yield put(statusAction(constants.LOAD_MACHINES, actionStatus.START));

  try {
    const { data } = yield api.loadMachines(organizationId);
    yield put(statusAction(constants.LOAD_MACHINES, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_MACHINES, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleLoadMachinesWithGateway({ organizationId, gatewayId }) {
  yield put(statusAction(constants.LOAD_MACHINES_WITH_GATEWAY, actionStatus.START));

  try {
    const { data } = yield api.loadMachines(organizationId, gatewayId);
    yield put(statusAction(constants.LOAD_MACHINES_WITH_GATEWAY, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_MACHINES_WITH_GATEWAY, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleLoadMachine({ id }) {
  yield put(statusAction(constants.LOAD_MACHINE, actionStatus.START));
  try {
    const { data } = yield call(api.loadMachine, id);
    // merge with initial values to handle bad backend response
    const machine = merge(initialMachineValues, data);
    yield put(statusAction(constants.LOAD_MACHINE, actionStatus.SUCCESS, { payload: machine }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleLoadMachinePlcType({ id }) {
  yield put(statusAction(constants.LOAD_MACHINE_PLC_TYPE, actionStatus.START));
  try {
    const initialPlcType = {
      plc_type: '',
      autoconfig_enabled: false
    };

    const { data } = yield api.loadMachinePlcType(id);
    const plcType = merge(initialPlcType, data);

    yield put(statusAction(constants.LOAD_MACHINE_PLC_TYPE, actionStatus.SUCCESS, { payload: plcType }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_MACHINE_PLC_TYPE, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleLoadMetricsMachineDetail({ machineId }) {
  yield put(statusAction(constants.METRICS_MACHINE_DETAILS, actionStatus.START));
  try {
    const { data } = yield api.loadMetricsMachineDetails(machineId);
    yield put(statusAction(constants.METRICS_MACHINE_DETAILS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.METRICS_MACHINE_DETAILS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleLoadSensorMachine({ id }) {
  yield put(statusAction(constants.LOAD_SENSOR_MACHINE, actionStatus.START));
  try {
    const { data } = yield api.loadSensorMachine(id);
    yield put(statusAction(constants.LOAD_SENSOR_MACHINE, actionStatus.SUCCESS, { payload: data }));
    yield put({ type: constants.METRICS_MACHINE_DETAILS, machineId: id });
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_SENSOR_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleCreateSensor({ machineId, payload }) {
  yield put(statusAction(constants.CREATE_SENSOR_MACHINE, actionStatus.START));

  try {
    const { data } = yield api.createSensor(machineId, payload);
    yield put(statusAction(constants.CREATE_SENSOR_MACHINE, actionStatus.SUCCESS, { payload: data }));

    yield call(handleLoadSensorMachine, { id: machineId });
    yield call(handleLoadMetricsMachineDetail, { machineId });

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.CREATE_SENSOR_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateStaticValue({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_STATIC_VALUE, actionStatus.START));

  try {
    const { data } = yield api.updateStaticValue(machineId, payload);
    yield put(statusAction(constants.UPDATE_STATIC_VALUE, actionStatus.SUCCESS, { payload: data }));

    yield call(handleLoadSensorMachine, { id: machineId });

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_STATIC_VALUE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleDeleteSensor({ machineId, sensorType }) {
  try {
    const { data } = yield api.deleteSensor(machineId, sensorType);
    yield put(statusAction(constants.DELETE_SENSOR_BY_ID, actionStatus.SUCCESS, { payload: data }));
    yield call(handleLoadSensorMachine, { id: machineId });

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: 'form.error'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield call(handleLoadSensorMachine, { id: machineId });
    yield put(
      statusAction(constants.DELETE_SENSOR_BY_ID, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: `form.success`
      })
    );
  }
}

function* handleLoadPlcMachine({ id }) {
  yield put(statusAction(constants.LOAD_PLC_MACHINE, actionStatus.START));
  try {
    const { data } = yield api.loadPlcMachine(id);
    yield put(statusAction(constants.LOAD_PLC_MACHINE, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_PLC_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleLoadPlcMachineIris({ machineId }) {
  yield put(statusAction(constants.LOAD_PLC_MACHINE_IRIS, actionStatus.START));
  try {
    const { data } = yield api.loadPlcMachineIris(machineId);
    yield put(statusAction(constants.LOAD_PLC_MACHINE_IRIS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_PLC_MACHINE_IRIS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdatePlcMachine({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_PLC_MACHINE, actionStatus.START));

  try {
    const { data } = yield api.updatePlcMachine(machineId, payload);
    yield put(statusAction(constants.UPDATE_PLC_MACHINE, actionStatus.SUCCESS, { payload: data }));

    yield put({ type: constants.LOAD_PLC_MACHINE, id: machineId });
    yield put({ type: constants.LOAD_MACHINE_PLC_TYPE, id: machineId });

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_PLC_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdatePlcMachineIris({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_PLC_MACHINE_IRIS, actionStatus.START));

  try {
    const { data } = yield api.updatePlcMachineIris(machineId, payload);
    yield put(statusAction(constants.UPDATE_PLC_MACHINE_IRIS, actionStatus.SUCCESS, { payload: data }));

    yield put({ type: constants.LOAD_PLC_MACHINE_IRIS, machineId });
    yield put({ type: constants.LOAD_MACHINE_PLC_TYPE, id: machineId });

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_PLC_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleLoadErrorCode({ machineId }) {
  yield put(statusAction(constants.LOAD_ERROR_CODE, actionStatus.START));

  try {
    const { data } = yield api.loadErrorCode(machineId);

    yield put(statusAction(constants.LOAD_ERROR_CODE, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_ERROR_CODE, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleLoadErrorCodeById({ machineId, errorId }) {
  yield put(statusAction(constants.LOAD_ERROR_CODE_BY_ID, actionStatus.START));

  try {
    const { data } = yield api.loadErrorCodeById(machineId, errorId);

    yield put(statusAction(constants.LOAD_ERROR_CODE_BY_ID, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_ERROR_CODE_BY_ID, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleCreateErrorCode({ machineId, payload }) {
  yield put(statusAction(constants.CREATE_ERROR_CODE, actionStatus.START));

  try {
    const { data } = yield api.createErrorCode(machineId, payload);
    yield put(statusAction(constants.CREATE_ERROR_CODE, actionStatus.SUCCESS, { payload: data }));

    yield call(handleLoadErrorCode, { machineId });

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success_error_code'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.CREATE_ERROR_CODE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateErrorCode({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_ERROR_CODE, actionStatus.START));

  try {
    const { data } = yield api.updateErrorCode(machineId, payload);
    yield put(statusAction(constants.UPDATE_ERROR_CODE, actionStatus.SUCCESS, { payload: data }));

    yield call(handleLoadErrorCode, { machineId });

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_ERROR_CODE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleDeleteErrorCode({ machineId, errorId }) {
  try {
    const { data } = yield api.deleteErrorCode(machineId, errorId);
    yield put(statusAction(constants.DELETE_ERROR_CODE, actionStatus.SUCCESS, { payload: data }));

    yield call(handleLoadErrorCode, { machineId });

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.DELETE_ERROR_CODE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleLoadBatchRecordsMachine({ machineId }) {
  yield put(statusAction(constants.LOAD_BATCH_RECORDS_MACHINE, actionStatus.START));
  try {
    const { data } = yield api.loadBatchRecordsMachine(machineId);
    yield put(statusAction(constants.LOAD_BATCH_RECORDS_MACHINE, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_BATCH_RECORDS_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateBatchRecordsMachine({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_BATCH_RECORDS_MACHINE, actionStatus.START));

  try {
    const { data } = yield api.updateBatchRecordsMachine(machineId, payload);
    yield put(statusAction(constants.UPDATE_BATCH_RECORDS_MACHINE, actionStatus.SUCCESS, { payload: data }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success_batch_details'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_BATCH_RECORDS_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleLoadStatusMachine({ machineId }) {
  yield put(statusAction(constants.LOAD_STATUS_MACHINE, actionStatus.START));
  try {
    const { data } = yield api.loadStatusMachine(machineId);
    yield put(statusAction(constants.LOAD_STATUS_MACHINE, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_STATUS_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleCreateStatusMachine({ machineId, payload }) {
  yield put(statusAction(constants.CREATE_STATUS_MACHINE, actionStatus.START));

  try {
    const modifiedValues = yield call(verifyCommissionNumberAndPrepareModifiedValues, payload);
    const validMachineObject = convertToValidMachineBackendObject(modifiedValues);

    const { data } = yield api.createStatusMachine(machineId, validMachineObject);
    yield put(statusAction(constants.CREATE_STATUS_MACHINE, actionStatus.SUCCESS, { payload: data }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.CREATE_STATUS_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateStatusMachine({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_STATUS_MACHINE, actionStatus.START));

  try {
    const { data } = yield api.updateStatusMachine(machineId, payload);
    yield put(statusAction(constants.UPDATE_STATUS_MACHINE, actionStatus.SUCCESS, { payload: data }));
    yield call(handleLoadMetricsMachineDetail, { machineId });

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_STATUS_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${(error || '').toLowerCase()}`
      })
    );
  }
}

function* handleUpdateMachineDetails({ payload }) {
  yield put(statusAction(constants.EDIT_MACHINE, actionStatus.START));
  try {
    const { data } = yield api.editMachineDetails(payload);
    yield put(statusAction(constants.EDIT_MACHINE, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.EDIT_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleDeleteMachine({ machineId, organizationId }) {
  try {
    const { data } = yield api.deleteMachine(machineId);
    yield put(statusAction(constants.DELETE_MACHINE, actionStatus.SUCCESS, { payload: data }));

    yield put(push(organizationId ? `/customers/${organizationId}` : `/machines`));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.DELETE_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateFlagRequiredGatewayMachine({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_FLAG_REQUIRED_GATEWAY, actionStatus.START));
  try {
    const { data } = yield api.updateFlagRequiredGateway(machineId, payload);
    yield put(statusAction(constants.UPDATE_FLAG_REQUIRED_GATEWAY, actionStatus.SUCCESS, { payload: data }));

    const selectedMachine = yield select(getSelectedMachine);
    const newMachine = { ...selectedMachine.data, is_gateway_required: payload.required };
    yield put(statusAction(constants.LOAD_MACHINE, actionStatus.SUCCESS, { payload: newMachine }));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_FLAG_REQUIRED_GATEWAY, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateSensorStatus({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_SENSOR_STATUS, actionStatus.START));
  try {
    const { data } = yield api.updateSensorStatus(machineId, payload);
    yield put(statusAction(constants.UPDATE_SENSOR_STATUS, actionStatus.SUCCESS, { payload: data }));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_SENSOR_STATUS, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleLoadListMachinesResumed({
  organizationName,
  commissionNumber,
  serialNumber,
  limit,
  page,
  order,
  sort
}) {
  yield put(statusAction(constants.LOAD_MACHINE_RESUMED, actionStatus.START));
  try {
    const { data } = yield api.loadMachinesResumed(
      organizationName,
      commissionNumber,
      serialNumber,
      limit,
      page,
      order,
      sort
    );
    yield put(statusAction(constants.LOAD_MACHINE_RESUMED, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_MACHINE_RESUMED, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.LOAD_MACHINES, handleLoadMachines);
  yield takeLatest(constants.METRICS_MACHINE_DETAILS, handleLoadMetricsMachineDetail);
  yield takeLatest(constants.LOAD_PLC_MACHINE, handleLoadPlcMachine);
  yield takeLatest(constants.LOAD_PLC_MACHINE_IRIS, handleLoadPlcMachineIris);
  yield takeLatest(constants.LOAD_SENSOR_MACHINE, handleLoadSensorMachine);
  yield takeLatest(constants.LOAD_BATCH_RECORDS_MACHINE, handleLoadBatchRecordsMachine);
  yield takeLatest(constants.DELETE_SENSOR_BY_ID, handleDeleteSensor);
  yield takeLatest(constants.DELETE_ERROR_CODE, handleDeleteErrorCode);
  yield takeLatest(constants.LOAD_ERROR_CODE, handleLoadErrorCode);
  yield takeLatest(constants.LOAD_ERROR_CODE_BY_ID, handleLoadErrorCodeById);
  yield takeLatest(constants.LOAD_MACHINES_WITH_GATEWAY, handleLoadMachinesWithGateway);
  yield takeLatest(constants.CREATE_NEW_MACHINE, handleCreateNewMachine);
  yield takeLatest(constants.CREATE_MACHINE, handleCreateMachine);
  yield takeLatest(constants.CREATE_SENSOR_MACHINE, handleCreateSensor);
  yield takeLatest(constants.UPDATE_STATIC_VALUE, handleUpdateStaticValue);
  yield takeLatest(constants.UPDATE_SENSOR, handleUpdateSensor);
  yield takeLatest(constants.CREATE_ERROR_CODE, handleCreateErrorCode);
  yield takeEvery(constants.UPDATE_MACHINE, handleUpdateMachine);
  yield takeEvery(constants.UPDATE_PLC_MACHINE, handleUpdatePlcMachine);
  yield takeEvery(constants.UPDATE_PLC_MACHINE_IRIS, handleUpdatePlcMachineIris);
  yield takeEvery(constants.UPDATE_BATCH_RECORDS_MACHINE, handleUpdateBatchRecordsMachine);
  yield takeLatest(constants.LOAD_MACHINE, handleLoadMachine);
  yield takeLatest(constants.LOAD_MACHINE_PLC_TYPE, handleLoadMachinePlcType);
  yield takeLatest(constants.UPDATE_MACHINES_GATEWAY, handleUpdateMachinesGateway);
  yield takeLatest(constants.VERIFY_COMMISSION_NUMBER_ID, handleVerifyCommissionNumber);
  yield takeLatest(constants.LOAD_MACHINES_RESUME, handleLoadMachinesByResume);
  yield takeLatest(constants.LOAD_MACHINES_RESUMED_WITH_FILTER, handleFetchFiltered);
  yield takeLatest(constants.EDIT_MACHINE, handleUpdateMachineDetails);
  yield takeLatest(constants.DELETE_MACHINE, handleDeleteMachine);
  yield takeLatest(constants.UPDATE_FLAG_REQUIRED_GATEWAY, handleUpdateFlagRequiredGatewayMachine);
  yield takeLatest(constants.UPDATE_SENSOR_STATUS, handleUpdateSensorStatus);
  yield takeLatest(constants.LOAD_STATUS_MACHINE, handleLoadStatusMachine);
  yield takeLatest(constants.CREATE_STATUS_MACHINE, handleCreateStatusMachine);
  yield takeLatest(constants.UPDATE_STATUS_MACHINE, handleUpdateStatusMachine);
  yield takeLatest(constants.UPDATE_ERROR_CODE, handleUpdateErrorCode);
  yield takeLatest(constants.LOAD_MACHINE_RESUMED, handleLoadListMachinesResumed);
}
